<template>
    <div>
        <div class="container grid-md">
            <div class="columns">
                <div class="column col-6 hide-md">
                    <div id="signup-modal">
                        <div class="column">
                            <img id="header" src="../assets/img/mark.svg">

                            <div class="points">
                                <h4>Sign up for free</h4>
                                <p>Generate and track analytics for up to 5 products for free</p>

                                <h4>Start building instantly</h4>
                                <p>Easily build scannable experiences without code</p>

                                <h4>Collect real-time intelligence</h4>
                                <p>Find out which stores should be stocking your products</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column col-6 col-md-12">
                    <div id="signup-modal">
                        <div class="column">
                            <div class="card">
                                <div class="content">
                                    <h1>Create your CounterSet account now</h1>
                                    <form v-on:submit.prevent="signup()">
                                        <div v-if="error" class="toast toast-error">
                                            {{error.message}}
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label" for="input-email" >Email Address</label>
                                            <input class="form-input" type="email" id="input-email" v-model="organization.email">
                                        </div>
                                        <div class="form-group" v-bind:class="{ 'has-error': organization.name !== '' && (organization.name.length < 4 || organization.name.match(/^[a-zA-Z0-9]+$/) == null) }">
                                            <label class="form-label" for="input-organizationusername" >Organization Name</label>
                                            <input class="form-input" type="text" id="input-organizationusername" v-model="organization.name">
                                            <div v-show="organization.name !== ''">
                                                <p v-show="organization.name.length < 4" class="form-input-hint">Organization username must be longer than 4 characters</p>
                                                <p v-show="organization.name.match(/^[a-zA-Z0-9]+$/) == null" class="form-input-hint">Organization username must only contain numbers and letters (for example: exampleco1) </p>
                                            </div>
                                        </div>
                                        <div class="form-group" v-bind:class="{ 'has-error': organization.displayName !== '' && organization.displayName.length < 4 }">
                                            <label class="form-label" for="input-organizationname">Organization Display Name</label>
                                            <input class="form-input" type="text" id="input-organizationname" v-model="organization.displayName">
                                            <div v-show="organization.displayName !== ''">
                                                <p v-show="organization.displayName.length < 4" class="form-input-hint">Organization name must be longer than 4 characters</p>
                                            </div>

                                        </div>
                                        <div class="form-group" v-bind:class="{ 'has-error': organization.password !== '' && organization.password.length < 8 }">
                                            <label class="form-label" for="input-password">Password</label>
                                            <input class="form-input" id="input-password" v-model="organization.password" type="password">
                                            <div v-show="organization.password !== ''">
                                                <p v-show="organization.password.length < 8" class="form-input-hint">Passwords must be longer than 7 characters.</p>
                                            </div>
                                        </div>
                                        <div class="form-group" v-bind:class="{ 'has-error': organization.password !== '' &&organization.password != organization.confirmPassword }">
                                            <label class="form-label" for="input-confirmpassword">Confirm Password</label>
                                            <input class="form-input" id="input-confirmpassword" v-model="organization.confirmPassword" type="password">
                                            <div v-show="organization.password !== ''">
                                                <p v-show="organization.password != organization.confirmPassword" class="form-input-hint">Passwords do not match.</p>
                                            </div>
                                        </div>
                                        <vue-hcaptcha
                                            sitekey="cc462d62-fbad-4e58-827a-783f32784e78"
                                            @verify="onVerify"
                                            @expired="onExpire"
                                            @challengeExpired="onChallengeExpire"
                                            @error="onError"
                                        ></vue-hcaptcha>
                                        <p>By creating an account, you agree to the <a href="https://www.counterset.com/privacy" target="_blank">Privacy Policy</a> and the <a href="https://www.counterset.com/terms" target="_blank">Terms of Service</a>.</p>
                                        <div v-if="isLoading" class="loading loading-lg"></div>
                                        <div style="padding-top: 20px;" v-else>
                                            <o-button v-if="canSignup" @click.native="signup" v-bind:disabled="!canSignup"><div style="width: 300px">Create your BouncePage account</div></o-button>
                                            <o-button v-else v-bind:disabled="!canSignup"><div style="width: 300px">Create your BouncePage account</div></o-button>
                                        </div>
                                    </form>
                                    <p class="signin">
                                    Already have an account? <router-link :to="{ name: 'login'}">Sign in.</router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
export default {
    components: {
        VueHcaptcha
    },
    name: 'Signup',
    data() {
        return {
            context: 'signup',
            organization: {
                name: '',
                displayName: '',
                email: '',
                password: '',
                confirmPassword: ''
            },
            isLoading: false,
            error: null,

            //hcaptcha stuff
            verified: false,
            expired: false,
            token: null,
            eKey: null,
        };
    },
    mounted() {
        if (this.$auth.check()){
            this.$router.push({name: 'home'})
        }
    },
    computed: {
        canSignup() {
            const organization = this.organization;
            if (organization.name === '' || organization.displayName === '' || organization.email === '' || organization.password === '' || organization.confirmPassword  === '') {
                return false;
            }

            if (organization.displayName.length < 4 || organization.name.length < 4 || organization.password.length < 8) {
                return false
            }

            const usernameRegex = /^[a-zA-Z0-9]+$/;
            if (!organization.name.match(usernameRegex)){
                return false
            }

            if (organization.password !== organization.confirmPassword) {
                return false
            }

            if (this.verified == false) {
                return false
            }
            return true
        }
    },
    methods: {
        signup() {
            const organization = this.organization;
            this.isLoading = true;
            this.$http.post('/organizations', {
                name: organization.name,
                displayName: organization.displayName,
                email: organization.email,
                password: organization.password,
                token: this.token,
                properties: {
                    address: {
                        street_address: '',
                        city: '',
                        state: '',
                        country: ''
                    }
                }
            })
            .then((res) => {
                this.$auth.login({
                    body: {
                        username: organization.email,
                        password: organization.password
                    },
                    data: {
                        username: organization.email,
                        password: organization.password
                    },
                    redirect: '/dashboard/home',
                    fetchUser: true
                })
                .then(() => {
                    this.isLoading = false;
                }, (res) => {
                    this.isLoading = false;
                    console.log(res)
                    this.error = res;
                });
            }).catch((err) => {
                this.isLoading = false;
                this.error = err;
            });
        },

        //hcaptcha methods
        onVerify(token, ekey) {
            this.verified = true;
            this.token = token;
            this.eKey = ekey;
            console.log(`Callback token: ${token}, ekey: ${ekey}`);
        },
        onExpire() {
            this.verified = false;
            this.token = null;
            this.eKey = null;
            this.expired = true;
            console.log('Expired');
        },
        onChallengeExpire() {
            this.verified = false;
            this.token = null;
            this.eKey = null;
            this.expired = true;
            console.log(`Challenge expired`);
        },
        onError(err) {
            this.token = null;
            this.eKey = null;
            this.error = err;
            console.log(`Error: ${err}`);
        },
        onSubmit() {
            console.log('Submitting the invisible hCaptcha', this.$refs.invisibleHcaptcha);
            this.$refs.invisibleHcaptcha.execute();
        }
    }
}
</script>
<style lang="scss">
    body {
        background-color: #f7fafc;
    }
    #header {
        width: 180px;
        height: 42px;
    }
    .points {
        margin-top: 8px;
        margin-left: 6px;
        p {
            padding-bottom: 24px;
        }
    }
    #signup-modal {

        h1 {
            font-size: 0.9rem;
            text-align: center;
            margin-bottom: 24px;
        }

        h4 {
            font-size: 0.8rem;
            font-weight: 600;
        }
        width: 400px;
        margin: 100px auto;
        

        .card {
            padding: 15px;
        }
        .signin {
            padding-top: 16px;
            text-align: center;
        }
    }
    .btn {
        margin-top:1rem;
    }
</style>
