<template>
<div>
  <div class="link-modal">
      <div class="header">
        <h3>New Dynamic Link</h3>
      </div>
      <form>
        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
        <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
            <label class="form-label" for="name">Name* <tip-modal title="Name" text="The name is used to help you identify a dynamic link. (required)"></tip-modal></label>
            <input class="form-input" type="text" name="name" id="name" v-model="link.name">
            <p v-if="!errors[0]"> </p>
            <p v-else style="margin-bottom: 0px" class="form-input-hint">{{ errors[0] }}</p>
        </div>
        </ValidationProvider>
        <ValidationProvider name="uri" rules="required|url" v-slot="{ errors }">
          <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
            <label class="form-label" for="uri">Default Link URI* <tip-modal title="Default Link URI" text="This is the default location for this dynamic link. (required)"></tip-modal></label>
            <input class="form-input" type="text" id="uri" name="uri" v-model="link.uri" placeholder="https://example.com">
            <p v-if="!errors[0]"> </p>
            <p v-else style="margin-bottom: 0px" class="form-input-hint">{{ errors[0] }}</p>
          </div>
        </ValidationProvider>
        <ValidationProvider name="gtin" :rules="{ regex: /^[0-9]{14}$|^[0-9]{13}$|^[0-9]{12}$|^[0-9]{8}$/ }" v-slot="{ errors }">
          <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
              <label class="form-label" for="gtin">GTIN (UPC/EAN)</label>
              <input class="form-input" type="text" id="gtin" v-model="link.gtin" name="gtin" placeholder="Product GTIN (UPC/EAN)">
              <p v-if="!errors[0]"> </p>
              <p v-else style="margin-bottom: 0px" class="form-input-hint">{{ errors[0] }}</p>
          </div>
        </ValidationProvider>
        <div class="form-group">
            <label class="form-label" for="batchRef">Batch Reference</label>
            <input class="form-input" type="text" v-bind:disabled="!link.gtin" id="batchRef" v-model="link.batchRef">
            <p class="form-input-hint"></p>
        </div>
        
      </form>
      <div class="link-footer">
        <button class="button-tool" @click="newLink" v-bind:disabled="isDisabled">Create New Dynamic Link <arrow-right-circle-icon class="icon"></arrow-right-circle-icon></button>
      </div>
  </div>
  <div class="link-helper">
      <div class="container">
          <div class="columns">
              <div class="column col-2">
                   <info-icon></info-icon>
              </div>
              <div class="column col-10">
                   <p>You can create up to 5 dynamic links in the Free plan.
                    </p>
              </div>
          </div>
      </div>
  </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { VueAutosuggest } from 'vue-autosuggest';
import { ArrowRightCircleIcon, InfoIcon } from 'vue-feather-icons';
import { ValidationProvider, extend } from 'vee-validate';
import { required, regex } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('regex', {
  ...regex,
  message: 'This GTIN is invalid.'
});

extend('url', {
    validate: (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ 
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ 
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ 
            '(\\?[;&a-z\\d%_.~+=-]*)?'+
            '(\\#[-a-z\\d_]*)?$','i');
        return !!pattern.test(str);
    },
    message: 'This is not a valid URL'
})

import bugsnagClient from '../../utils/bugsnag';

import TipModal from '../../components/Tip';

export default {
  name: 'NewLinkModal',
  props: ['links', 'finishedCallback'],
  data() {
    return {
      link: {
        name: null,
        gtin: null,
        uri: null,
        batchRef: null
      }
    }
  },
  components: {
    ArrowRightCircleIcon, InfoIcon,
    VueAutosuggest, ValidationProvider, TipModal
  },
  computed:{
    isDisabled: function(){
      return !this.link.uri || !this.link.name
    }
  },
  methods: {
    ...mapActions('links', [
      'loadLinks'
    ]),
    newLink() {
      this.link.name = this.link.name.replace(/\t/g, "  ")
      this.$http.post(`/links`, this.link).then((res) => {
        this.loadLinks();
        if (this.finishedCallback) {
          this.finishedCallback(res.data.id)
        }
        this.$emit('close')
      }).catch((err) => {
        bugsnagClient.notify(err);
        this.$notify({
          type: 'error',
          title: 'Failed to create dynamic link.',
          text: 'Please try again later.'
        });
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .link-modal {
      padding: 16px;
  }
  .link-footer {
    float:right;
    margin-right: 0px;
    padding-top: 8px;

    .button-tool {
      float:right;
      margin: 0px;
      z-index: 9999999;
    }
    .footer-text {
      display: inline-block;
      margin: 16px;
    }
    margin-bottom: 4px;
  }
  .link-helper {
      background-color: #efefef;
      padding: 16px 16px 0px 8px;
      bottom: 0;
      position: absolute;
      width: 100%
  }
</style>
