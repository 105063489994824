<template>
<div>
  <div class="field-modal">
    <div class="header">
      <h3 v-if="initialField.attributeType == ''">New Replaceable Field</h3>
      <h3 v-else>Edit Replaceable Field</h3>
    </div>
    <form>
      <ValidationProvider name="name" rules="alpha_dash|required" v-slot="{ errors }">
        <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
          <label class="form-label" for="field-name">Field Name</label>
          <input class="form-input" type="text" id="field-name" v-model="field.name" name="name" placeholder="New Field Name">
          <p class="form-input-hint">{{ errors[0] }}</p>
        </div>
      </ValidationProvider>
      <div class="form-group">
        <label class="form-label" for="type">Field Type</label>
        <select class="form-select" id="attributeType" v-model="field.attributeType">
          <option @click="clearField()" v-for="(option, idx) in fieldTypes" v-bind:key="idx">
            {{ option }}
          </option>
        </select>
      </div>
      <div class="form-group" v-if="field.attributeType == 'text'">
        <label class="form-label" for="defaultVal">Field Default Value</label>
        <input class="form-input" type="text" id="defaultVal" v-model="field.defaultVal" placeholder="Field Default Value">
      </div>
      <div class="form-group" v-if="field.attributeType == 'file'">
        <label class="form-label" for="detail-file">Field Default File</label>
        <file-selector :maxFiles=1 v-bind:files="field.defaultVal" v-on:update="(file) => {field.defaultVal = file}"></file-selector>
      </div>
    </form>
    <div class="field-footer">

      <button class="button-tool" @click="newField" v-bind:disabled="field.name == '' || field.attributeType == ''">
        <span v-if="initialField.attributeType == ''">Create New Replaceable Field </span>
        <span v-else>Edit Replaceable Field </span>
      <arrow-right-circle-icon class="icon"></arrow-right-circle-icon></button>
    
    </div>
  </div>
  <div class="field-helper">
    <div class="container">
      <div class="columns">
        <div class="column col-2">
          <info-icon></info-icon>
        </div>
        <div class="column col-10">
          <p>A replaceable field is a field that can be edited during batch creation.</p>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ArrowRightCircleIcon, InfoIcon } from 'vue-feather-icons';
import { ValidationProvider, extend } from 'vee-validate';
import { required, alpha_dash } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('alpha_dash', {
  ...alpha_dash,
  message: 'This field must only contain alphanumeric and dash characters'
})

import FileSelector from '../../components/FileSelector';

export default {
  name: 'FieldModal',
  props: {
    initialField: {
      type: Object,
      default: () => {
        return {
          name: 'untitled',
          defaultVal: '',
          attributeType: ''
        }
      }
    },
    finishedCallback: {
      default: (field) => {}
    }
  },
  data() {
    return {
      field: Vue.util.extend({}, this.initialField),
      fieldTypes: [
        'text',
        'file'
      ]
    }
  },
  components: {
    ArrowRightCircleIcon, InfoIcon,
    FileSelector, ValidationProvider
  },
  methods: {
    clearField(){
      this.field.defaultVal = '';
    },
    newField() {
      this.finishedCallback(this.field);
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>
  .field-modal {
    padding: 16px;
  }
  .field-footer {
    float:right;
    margin-right: 0px;
    padding-top: 24px;
    margin-bottom: 4px;
    .button-tool {
      float:right;
      margin: 0px;
      z-index: 9999999;
    }
    .footer-text {
      display: inline-block;
      margin: 16px;
    }
  }
  .field-helper {
    background-color: #efefef;
    padding: 16px 16px 0px 8px;
    bottom: 0;
    position: absolute;
    width: 100%
  }
</style>
