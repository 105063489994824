import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueAuth from '@websanova/vue-auth';
import VueGoodTablePlugin from 'vue-good-table';
import VueApexCharts from 'vue-apexcharts';
import VModal from 'vue-js-modal';
import InputTag from 'vue-input-tag';
import vSelect from 'vue-select';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import VueAnalytics from 'vue-analytics';
import Notifications from 'vue-notification';

import App from './App.vue';
import router from './router';
import auth from './auth';
import store from './store';
import OButton from './components/OButton';
import VTooltip from 'v-tooltip';

import http from '@websanova/vue-auth/drivers/http/axios.1.x.js';
import vrouter from '@websanova/vue-auth/drivers/router/vue-router.2.x.js';

import bugsnagClient from './utils/bugsnag';

Vue.use(VueAxios, axios);
Vue.use(VueGoodTablePlugin);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.use(VModal, {
  dialog: true,
  dynamic: true,
});
Vue.component('v-select', vSelect);
Vue.component('input-tag', InputTag);
Vue.component('o-button', OButton);
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.use(Notifications);
Vue.use(VTooltip);

Vue.axios.defaults.baseURL = process.env.VUE_APP_PAIZA_ENDPOINT;

Vue.config.sulde = process.env.VUE_APP_SULDE_ENDPOINT;
Vue.config.stripeKey = process.env.VUE_APP_STRIPE_KEY;
Vue.config.productionTip = false;

// Global uncaught error handling
Vue.config.errorHandler = function(err) {
  bugsnagClient.notify(err);
}

Vue.router = router;
Vue.use(VueAuth, {
  auth: auth,
  http: http,
  router: vrouter,
  rolesVar: 'roles',
  loginData: {
    url: '/auth/login',
    redirect: '/dashboard/home'
  },
  fetchData: {
    url: '/identity',
    enabled: true
  },
  refreshData: {
    url: '/auth/refresh',
    method: 'POST',
    interval: 2
  },
  parseUserData: function (data) {
    return {
      id: data.id,
      username: data.username,
      organizationId: data.organizationId,
      roles: data.claims.roles,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt
    }
  }
});

Vue.use(VueAnalytics, {
  id: 'UA-149402600-1',
  debug: {
    sendHitTask: process.env.VUE_APP_PAIZA_ENDPOINT === 'https://api.counterset.com'
  },
  router
});

new Vue({
  router: Vue.router,
  store,
  render: h => h(App)
}).$mount('#app')