import Vue from 'vue';
import Vuex from 'vuex';

import notifications from './modules/notifications';
import scans from './modules/scans';
import templates from './modules/templates';
import files from './modules/files';
import batches from './modules/batches';
import account from './modules/account';
import links from './modules/links';
import admin from './modules/admin';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        notifications,
        scans,
        templates,
        files,
        batches,
        account,
        links,
        admin
    }
});