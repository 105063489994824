<template>
  <div class="container grid-lg">
    <modals-container>
    </modals-container>
    <div class="columns">
      <div class="column col-12">
        <div class="card">
          <div v-show="loaded" class="content">
            <div class="columns">
            </div>
          </div>
          <div v-if="!loaded" class="loading loading-lg"></div>
          <div v-else>
            <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
            :columns="columns"
            :rows="forms"
            :totalRows="count"
            :search-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: true
            }"
            :sort-options="{
              enabled: true,
              initialSortBy: {field: 'createdAt', type: 'desc'}
            }"
            :isLoading.sync="isLoading"
            @on-row-click="editFile"
            @on-search="onSearch"
            @on-sort-change="onSortChange">
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'response'">
                <pre>{{props.formattedRow[props.column.field]}}</pre>
              </div>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
const _ = require('lodash');
import { PlusCircleIcon, FileTextIcon } from 'vue-feather-icons';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import Dashboard from '@uppy/dashboard';
import bugsnagClient from '../utils/bugsnag';
import FileModal from './modals/FileModal';
import moment from 'moment-timezone';

export default {
  name: 'Forms',
  components: {
    PlusCircleIcon, FileTextIcon
  },

  data() {
    return {
      columns: [  
        {
          label: 'Response',
          field: 'response',
          formatFn: this.resFmt
        },
        {
          label: 'Uploaded On',
          field: 'createdAt',
          type: 'date',
          formatFn: this.formatFn,
        }
      ],
      loaded: false,
      isLoading: false,
      params: {
        page: 1,
        limit: 10,
        search: '',
        sort: {
          field: '', 
          type: '',
        },
      },
      forms: [],
      count: 0
    };
  },
  methods: {
    formatFn: function(value) {
      var d = moment.tz(value,"UTC")
      return d.tz(moment.tz.guess()).format("MMM Do, YYYY HH:mm");
    },
    resFmt: function(values){
      let string = "";
      for (const value in values)
      {
        string += value + ": \n" + values[value];
        string += '\n';
      }
      string = string.slice(0,-1);
      return string;
    },
    async loadForms() {
      this.isLoading = true;
      let res = await Vue.axios.get('/forms', {
        params: this.params
      });
      this.forms = res.data.responses;
      this.count = res.data.count;
      this.isLoading = false;
    },
  
    identityFormatter (identityId) {
      let identity = this.identities[identityId]
      return identity.username;
    },
    tagFormatter (tags) {
      return tags.join(', ');
    },
    updateParams(newProps) {
      this.params = Object.assign({}, this.params, newProps);
    },
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadForms()
    },
    onPerPageChange(params) {
      this.updateParams({limit: params.currentPerPage});
      this.loadForms()
    },
    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.loadForms();
    },
    onSearch: _.debounce(function(params) {
      if (params.searchTerm.length == 0){
        this.updateParams({
          page: params.page = 1,
          search: params.searchTerm = ''
        })
        this.loadForms()
      } else if (params.searchTerm.length < 3){
        return;
      }
      this.updateParams({search: params.searchTerm});
      this.loadForms();
    }, 200),
  },
  created() {
    this.loadForms().then(() => {
      this.loaded = true;
    }).catch((err) => {
      this.isLoading = false;
      bugsnagClient.notify(err);
      this.$notify({
        type: 'error',
        title: 'Failed to retrieve forms.',
        text: 'Please try again later.'
      })
    })
  }
}
</script>
<style lang="scss" scoped>
  .previewImage {
    width: 80px;
    height: auto;
    border-radius: 8px;
  }
  .previewFile {
    text-align: center;
  }
  pre {
    font-family: "Lato", sans-serif;
  }
</style>
