<template>
    <div class="container grid-lg">
        <modals-container>
        </modals-container>
        <div class="columns"> 
            <div class="column">    
            <div class="card">
                <div class="content">
                    <div v-if="plan">
                        <h4>Usage</h4>
                        <div>
                            <p> You are currently subscribed to the {{ plan.tier | planTier }} plan.</p>
                        </div>
                        <p>
                            {{plan.balance}} Unique Codes Remaining
                        </p>
                        <p>
                            <button class="button-tool" @click="manageSubscription"><plus-circle-icon class="icon"></plus-circle-icon> Manage Subscription</button>
                        </p>
                    </div>
                    <div v-else>
                        <div class="loading loading-lg"></div>
                    </div>
                </div>
            </div>   
            <div class="card">
                <div class="content">
                    <div v-if="identity">
                        <h2>Account</h2>
                        <p>Username: {{identity.username}}</p>
                        <p>Organization Name: {{organization.name}}</p>
                        <p>Display Name: {{organization.displayName}}</p>
                        <button class="button-tool" @click="changePassword">Change Password</button>
                    </div>
                    <div v-else>
                        <div class="loading loading-lg"></div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="content">
                    <div v-if="organization">
                        <h2>Organization</h2>
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="input-displayname">Display Name</label>
                                <input class="form-input" type="text" id="input-displayname" placeholder="Display Name" v-model="displayName">
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="input-brands">Brands</label>
                                <input-tag id="input-brands" class="form-input" :add-tag-on-blur="true" v-model="brands"></input-tag>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="input-address">Address</label>
                                <input class="form-input" type="text" id="input-address" placeholder="Street Address" v-model="address.street_address">
                                <input class="form-input" type="text" id="input-addres" placeholder="City" v-model="address.city">
                                <input class="form-input" type="text" id="input-address" placeholder="State" v-model="address.state">
                                <input class="form-input" type="text" id="input-address" placeholder="Country" v-model="address.country">
                            </div>
                            <div class="form-group">
                                <o-button :loading="savingOrganization" @click.native="saveOrganization">Save Organization</o-button>
                            </div>
                        </form>
                    </div>
                    <div v-else>
                        <div class="loading loading-lg"></div>
                    </div>
                </div>
            </div>

            <div class="card" v-show="false">
                <div class="content">
                    <h4>API Keys</h4>
                    <div class="columns">
                        <div class="col-12 button-top">
                            <button class="button-tool" @click="newAPIKey"><plus-circle-icon class="icon"></plus-circle-icon> New API Key</button>
                        </div>
                    </div>
                </div>
                <vue-good-table
                v-if="apikeys"
                :columns="apikeysColumns"
                :rows="apikeys"
                :search-options="{
                    enabled: true
                }">
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                    before
                    </span>
                    <span v-else>
                    {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
                </vue-good-table>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PlusCircleIcon } from 'vue-feather-icons';

import bugsnagClient from '../utils/bugsnag';

import PaymentMethod from '../components/PaymentMethod';
import SubscriptionModal from './modals/SubscriptionModal';
import ChangePasswordModal from './modals/ChangePasswordModal';

export default {
    name: 'Settings',
    components: {
        PlusCircleIcon, PaymentMethod
    },
    filters: {
        planTier: function (tier) {
      if (!tier) return ''
      switch (tier) {
                case 'free':
                    return 'Community';
                case 'starter_m':
                    return 'Team (Monthly)'
                case 'pro_m':
                    return 'Pro (Monthly)'
                case 'starter_a':
                    return 'Team (Annual)'
                case 'pro_a':
                    return 'Pro (Annual)'
                default:
                    return '';
            }
    }
    },
    data() {
        return {
            savingOrganization: false,
            displayName: '',
            brands: [],
            address: {
                street_address: '',
                city: '',
                state: '',
                country: ''
            },
            apikeysColumns: [
                {
                    label: 'Name',
                    field: 'name',
                    filterable: true,
                },
                {
                    label: 'API Key',
                    field: 'key'
                },
                {
                    label: 'Expires at',
                    field: 'expiresAt',
                    type: 'date',
                    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS\'Z\'',
                    dateOutputFormat: 'MMM dd, yyyy HH:mm'
                },
                {
                    label: 'Actions',
                    field: 'actions'
                },
            ]
        };
    },
    computed: {
        ...mapGetters('account', {
            identity: 'identity',
            organization: 'organization',
            plan: 'plan',
            apikeys: 'apikeys'
        })
    },
    methods: {
        ...mapActions('account', [
          'loadIdentity',
          'loadOrganization',
          'loadPlan',
          'loadAPIKeys'
        ]),
        changeSubscription(){
            this.$modal.show(SubscriptionModal, {
                finishedCallback: () => {
                // refresh credits
                }
            }, {
                adaptive: true,
                minHeight: 400,
                height: 'auto',
                scrollable: false
            })
        },
        manageSubscription(){
            this.$http.get(`/subscription`).then((subRes) => {
                console.log(subRes)
                window.open(subRes.data);
            }).catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to open manage subscription.',
                    text: 'Please try again later.'
                })
            })
        },
        changePassword() {
            this.$modal.show(ChangePasswordModal, {
            }, {
                adaptive: true,
                minHeight: 400,
                scrollable: false,
                height: 'auto'
            })
        },
        saveOrganization() {
            this.savingOrganization = true;
            this.$http.put('/organization', {
                displayName: this.displayName,
                properties: {
                    address: this.address,
                    brands: this.brands
                }
            }).then((res) => {
                this.savingOrganization = false;
            }).catch((err) => {
                this.savingOrganization = false;
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to update organization.',
                    text: 'Please try again later.'
                })
            })
        },
        amountFormatter(amount){
            let a = amount / 100;
            return `$${a}`;
        },
        newAPIKey(){
        }
    },
    created() {
        this.loadIdentity()
            .then(this.loadPlan())
            .then(() => {
                return this.loadOrganization().then(() => {
                    this.displayName = this.organization.displayName;
                    if (this.organization.properties.brands && this.organization.properties.brands.length) {
                        this.brands = this.organization.properties.brands;
                    }
                    this.address = Vue.util.extend(this.address, this.organization.properties.address);
                })
            })
            .then(this.loadAPIKeys())
            .catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to load details for your organization.',
                    text: 'Please try again later.'
                })
            })
    }
}
</script>
<style lang="scss" scoped>
.small-label {
    font-size: 12px;
}

</style>
