<template>
  <div class="columns">
    <modals-container>
    </modals-container>
    <div class="column col-2 col-sm-12">
        <ul class="menu hide-sm">
            <div id="header">
                <router-link active-class="active" :to="{ name: 'home'}"><img id="header-icon" src="../assets/img/mark-circle.svg"></router-link>
            </div>
            <li class="menu-item">
                <router-link active-class="active" :to="{ name: 'home'}">Home</router-link>
            </li>
            <li class="menu-item">
                <router-link active-class="active" :to="{ name: 'analytics'}">Analytics</router-link>
            </li>
            <li class="menu-item">
                <router-link active-class="active" :to="{ name: 'batches'}">Batches</router-link>
            </li>
            <li class="menu-item">
                <router-link active-class="active" :to="{ name: 'templates'}">Templates</router-link>
            </li>
            <li class="menu-item">
                <router-link active-class="active" :to="{ name: 'files'}">Files</router-link>
            </li>
            <li class="menu-item">
                <router-link active-class="active" :to="{ name: 'forms'}">Forms</router-link>
            </li>
            <li class="menu-item">
                <router-link active-class="active" :to="{ name: 'links'}">Dynamic Links</router-link>
            </li>
            <li class="menu-item">
                <router-link active-class="active" :to="{ name: 'settings'}">Settings</router-link>
            </li>
            
            <li class="menu-item" v-if="identity && identity.claims.roles.includes('master')">
                <router-link active-class="active" :to="{ name: 'admin'}">Administration</router-link>
            </li>

            <li class="menu-item">
                <a style="cursor: pointer" v-on:click="logout">Log out</a>
            </li>
        </ul>
    </div>
    <div id="content" class="column col-10 col-sm-12">
        <router-view></router-view>
    </div>
  </div>
</template>
<script>
import ConfirmDialog from '../components/ConfirmDialog';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'app',
  computed: {
    ...mapGetters('account', {
        identity: 'identity',
        organization: 'organization',
        plan: 'plan',
    })
  },
  methods: {
      ...mapActions('account', [
          'loadIdentity',
          'loadOrganization',
          'loadPlan'
      ]),
      logout: function(e){
        this.$modal.show(ConfirmDialog, {
            finishedCallback: (confirmed) => {
                if (confirmed) {
                    this.$auth.logout({redirect: '/login'});
                    
                }
            },
            message: 'Are you sure you want to log out?',
            primaryButtonTitle: 'Log Out'
        }, {
            height: 120,
            width: 300,
            scrollable: false
        })
      }
  },
  async mounted() {
    await this.loadIdentity();
    await this.loadOrganization();
    await this.loadPlan();
    
  },
}
</script>
<style lang="scss" scoped>
#content {
    overflow-y: auto;
}
.menu {
    position: fixed;
}
</style>
