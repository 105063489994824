<template>
    <div style="height: auto; position: absolute; top: 0;left: 0;right: 0;bottom: 0;">
        <div id="bar">This preview automatically updates every five seconds. Close the window when you're done</div>
        <iframe style="width: 100%; height: 100%; border: 0; top:0; z-index: -1" id="template"></iframe>
    </div>
</template>

<script>
import bugsnagClient from '../utils/bugsnag';

export default {
    name: 'Preview',
    data() {
        return {
            templateObj: ""
            }
    },
    mounted() {
        this.preview()
    },
    methods: {
            renderTemplate() {
                const data =  localStorage.getItem("template");
                this.$http.post(`/preview`, 
                    data
                ).then((res) => {
                    document.getElementById("template").srcdoc = res.data
                }).catch((err) => {
                    bugsnagClient.notify(err);
                    let res = err.response.data
                    console.log(res.split(/:(.+)/)[1])
                    this.$notify({
                    type: 'error',
                    title: 'Failed to get preview HTML',
                    text: 'Please check the payload for the following issue(s):' + res.split(/:(.+)/)[1].replaceAll(" Must validate one and only one schema (oneOf),",""),
                    duration: 10000,
                    })
                })
            },
            preview(){
                if(this.templateObj == "") {
                        this.templateObj = localStorage.getItem("template");
                        this.renderTemplate();
                    }
                setInterval(function () {
                    if (typeof(Storage) !== "undefined") {
                        if (this.templateObj != localStorage.getItem("template")) {
                            this.templateObj = localStorage.getItem("template");
                            this.renderTemplate();
                        } 
            } else {
                document.getElementById("result").innerHTML = "Sorry, your browser does not support Web Storage...";
            }
        }.bind(this), 5000)}
    }
};
</script>
<style>
html,body { height: 100%; margin: 0px; padding: 0px; }
      #full { background: #0f0; height: 100% }
#bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky; 
    top:0; 
    min-height: 42px;
    padding: 8px;
    height: fit-content;
    background: white;
    text-align: center;
}
</style>