<template>
    <div v-if="loading" class="loading loading-lg"></div>
    <div v-else>
        <div v-if="!editing">
            <div v-if="paymentMethod">
                <div class="method">
                    <div class="card-img" v-bind:class="cardClasses"></div>
                    **** {{paymentMethod.last4}}
                    <span class="exp">{{paymentMethod.exp}}</span>
                </div>
                <o-button @click.native="editMethod">Edit Payment Method</o-button>
            </div>
            <div v-else>
                <p>A payment method has not been added.</p>
                <o-button @click.native="editMethod">Add Payment Method</o-button>
            </div>
        </div>
        <div v-else>
            <card class='stripe-card'
                :class='{ complete }'
                :stripe="stripeKey"
                @change='complete = $event.complete'
            />
            <o-button buttonStyle="alt" @click.native="editMethod">Cancel</o-button> <o-button @click.native="saveMethod">Save Payment Method</o-button>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Card, createToken } from 'vue-stripe-elements-plus';

import bugsnagClient from '../utils/bugsnag';

import OButton from './OButton';

export default {
  name: 'PaymentMethod',
  components: {
    Card, OButton
  },
  mounted(){
    this.loadPaymentMethod().then(() => {
      this.loading = false;
    }).catch((err) => {
      bugsnagClient.notify(err);
    })
  },
  data() {
    return {
        loading: true,
        editing: false,
        stripeKey: Vue.config.stripeKey
    }
  },
  computed: {
    ...mapGetters('account', {
        paymentMethod: 'paymentMethod'
    }),
    cardClasses() {
        if (!this.paymentMethod) return {};
        let cType = this.paymentMethod.brand.split(' ').join('')
        return cType;
    }
  },
  methods: {
    ...mapActions('account', [
        'loadPaymentMethod'
    ]),
    editMethod() {
        this.editing = !this.editing;
    },
    saveMethod() {
        createToken().then(data => {
            this.loading = true;
            this.$http.put(`/payment`, {
                token: data.token.id
            }).then(() => {
                this.loadPaymentMethod().then(() => {
                    this.loading = false;
                });
            }).catch((err) => {
                console.log(err)
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to update payment method.',
                    text: 'Please try again later.'
                })
            })
        });
    }
  }
}
</script>
<style lang="scss" scoped>

.stripe-card.complete {
  border-color: green;
}

.stripe-card {
  box-sizing: border-box;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 450px;
  height: 40px;
  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #d3dfee;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.stripe-card--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.stripe-card--invalid {
  border-color: #fa755a;
}

.stripe-card--webkit-autofill {
  background-color: #fefde5 !important;
}

.method {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
    padding: 8px;
    background-color: #fafafa;
    width: 200px;
    border-radius: 8px;
}

.card-img {
    background-size: cover;
    height: 30px;
    width: 50px;
    margin-bottom: 8px;
}

.AmericanExpress {
    background-image: url("../assets/img/cc/AmericanExpress.svg");
}

.DinersClub {
    background-image: url("../assets/img/cc/DinersClub.svg");
}

.Discover {
    background-image: url("../assets/img/cc/Discover.svg");
}

.JCB {
    background-image: url("../assets/img/cc/JCB.svg");
}

.MasterCard {
    background-image: url("../assets/img/cc/MasterCard.svg");
}

.UnionPay {
    background-image: url("../assets/img/cc/UnionPay.svg");
}

.Visa {
    background-image: url("../assets/img/cc/Visa.svg");
}

.exp {
    font-stretch: expanded;
}
</style>
