<template>
  <div class="credit-modal">
      <div class="header">
        <h3>Purchase Unique Codes</h3>
      </div>
      <div class="container">
          <div class="columns">
              <div class="column col-12">
                  <div class="translate-wrapper">
                    <div v-if="!enterPayment">
                      <p>A unique code is assigned to an individual product unit, like a serial number. </p>
                      <label class="form-label" for="quantity">Select Quantity</label>
                      <v-select label="title" id="quantity" :options="options" v-model="quantity"></v-select>
                        <p class="total-wrapper">
                          <span class="total">Total: ${{quantity.price}}</span>
                          <o-button @click.native="proceed">Proceed to payment</o-button>
                        </p>
                    </div>
                    <div v-else>
                      <o-button buttonStyle="alt" @click.native="returnToQuantity">Return to Quantity Selection</o-button>
                      <payment-method class="pay"></payment-method>
                      <p class="total-wrapper">
                        <span class="total">Total: ${{quantity.price}}</span>
                        <o-button @click.native="purchase" :disabled='!paymentMethod'>Pay Now</o-button>
                      </p>
                  </div>
                </div>
          </div>
      </div>
  </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ArrowRightCircleIcon } from 'vue-feather-icons';
import PaymentMethod from '../../components/PaymentMethod';

export default {
  name: 'CreditsModal',
  data() {
    return {
      enterPayment: false,
      quantity: { quantity: 100, title: "100 Unique Codes", price: 15.00 },
      options: [
        { quantity: 100, title: "100 Unique Codes", price: 15.00 },
        { quantity: 1000, title: "1,000 Unique Codes", price: 100.00 },
        { quantity: 10000, title: "10,000 Unique Codes", price: 500.00 },
        { quantity: 25000, title: "25,000 Unique Codes", price: 1125.00 },
        { quantity: 50000, title: "50,000 Unique Codes", price: 2000.00 },
        { quantity: 100000, title: "100,000 Unique Codes", price: 3500.00 }
      ],
      stripeKey: Vue.config.stripeKey,
      methodSelected: false
    }
  },
  components: {
    ArrowRightCircleIcon,
    PaymentMethod
  },
  computed: {
    ...mapGetters('account', {
      paymentMethod: 'paymentMethod'
    })
  },
  mounted() {
  },
  methods: {
    ...mapActions('account', [
      'loadPlan',
      'loadInvoices'
    ]),
    proceed: function(){
      this.enterPayment = true;
    },
    returnToQuantity: function(){
      this.enterPayment = false;
    },
    purchase: function(){
      this.$http.post(`/credit`, {
        creditQuantity: this.quantity.quantity
      }).then((res) => {
        this.loadPlan();
        this.loadInvoices();
        this.$emit('close')
      }).catch((err) => {
        console.log(err);
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  
  .credit-modal {
      padding: 16px;
  }

  .button-tool {
      width: 100%;
  }

  .total-wrapper {
    padding-top:16px;
    padding-bottom:16px;
    float: right;
  }

  .total {
    font-size: 16px;
    margin-right: 8px;
  }

  .pay {
    margin-top: 16px;
    margin-bottom: 16px;
  }

</style>
