<template>
    <div>
        <div class="container">
            <div id="login-modal">
                <div class="column">
                    <div class="card">
                        <div class="content">
                            <h2>Forgot your Password?</h2>
                            <div v-if="!resetsent">
                                <p>We'll sort it out. Put in your email, and if we have an associated account, we'll send you a reset link</p>
                                <div v-if="error" class="toast toast-error">
                                    {{error.message}}
                                </div>
                                <div class="form-group">
                                    <label class="form-label">Email</label>
                                    <input class="form-input" type="text" id="input-email" v-model="reset.email">
                                </div>
                                <div v-if="isLoading" class="loading loading-lg"></div>
                                <div v-else>
                                    <button type="submit" class="btn btn-primary" @click="requestReset">Reset Password</button>
                                </div>
                            </div>
                            <div v-else>
                                <p>Password reset successfully requested. Please check your email for a reset link.</p>
                            </div>
                        </div>
                    </div>
                    <a style="cursor: pointer;" @click="() => this.$router.push({name: 'login'})">Return to Login</a>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import axios from 'axios';
import bugsnagClient from '../utils/bugsnag';

export default {
    name: 'Forgot',
    data() {
        return {
            context: 'forgot',
            reset: {
                email: ''
            },
            isLoading: false,
            resetsent: false,
            error: null
        };
    },
    methods: {
        requestReset() {
            if (this.reset.email == '') {
                this.error = "Missing required email address";
                return;
            }
            this.isLoading = true;
            axios.post(`${process.env.VUE_APP_PAIZA_ENDPOINT}/auth/requestreset`, this.reset)
            .then((res) => {
                this.resetsent = true;
            })
            .catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to reset password.',
                    text: 'Please try again later.'
                })
            })
            .finally(() => {
                this.isLoading = false;
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    #login-modal {
        width: 360px;
        margin: 100px auto;
    }
    .btn {
        margin-top:1rem;
    }
</style>
