const state = {
    notifications: [],
};
  
const getters = {
    notifications: state => state.notifications,
};
  
const mutations = {
    addNotification: (state, notification) => {
        state.notifications = [notification, ...state.notifications];
    },
    popNotification: state => {
        state.notifications.splice(state.notifications.length - 1);
    },
};
  
const actions = {
    populateNotifications: ({ commit }, notification) => {
        commit('addNotification', notification);
        setTimeout(() => commit('popNotification'), 3000);
    },
};
  
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};