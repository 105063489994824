<template>
  <div class="container grid-lg">
    <modals-container>
    </modals-container>
    <div class="columns">
      <div class="column col-12">
        <div class="card">
          <div class="content">
            <div class="doc-panel">
              <div class="doc-item">
                A dynamic link lets you redirect users to webpages based on a set of configured rules.
              </div>
              <div class="doc-item" style="display:none">
                <button class="button-tool">View Documentation</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column col-12">
        <div class="card">
          <div class="content" v-if="loaded">
            <div class="columns">
              <div class="col-12 button-top">
                <button class="button-tool" @click="newLink"><plus-circle-icon class="icon"></plus-circle-icon> New Dynamic Link</button>
              </div>
            </div>
          </div>
          <div v-if="!loaded" class="loading loading-lg"></div>
          <div v-else>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              :columns="columns"
              :rows="links"
              :totalRows="count"
              :search-options="{
                enabled: true,
                placeholder: 'Search Links'
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: {field: 'updatedAt', type: 'desc'}
              }"
              :pagination-options="{
                enabled: true
              }"
              :isLoading.sync="isLoading"
              @on-row-click="openLink"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
            >
              <template slot="table-row" slot-scope="props">
                {{props.formattedRow[props.column.field]}}
              </template>
              <div slot="emptystate">
                No dynamic links found.
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const _ = require('lodash');
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PlusCircleIcon } from 'vue-feather-icons';

import bugsnagClient from '../utils/bugsnag';

import NewDigitalLink from './modals/NewDigitalLink';
import SubscriptionModal from './modals/SubscriptionModal';

import moment from 'moment-timezone';

export default {
  name: 'Links',
  components: {
    PlusCircleIcon
  },
  data() {
    return {
      columns: [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Batch Reference',
          field: 'batchRef'
        },
        {
          label: 'GTIN (UPC/EAN)',
          field: 'gtin'
        },
        {
          label: 'URI',
          field: 'uri'
        },
        {
          label: 'Created On',
          field: 'createdAt',
          type: 'date',
          formatFn: this.formatFn,
        },
        {
          label: 'Updated At',
          field: 'updatedAt',
          type: 'date',
          formatFn: this.formatFn,
        }
      ],
      loaded: false,
      isLoading: false,
      params: {
        page: 1,
        limit: 10,
        search: '',
        sort: {
            field: '', 
            type: ''
        }
      },
      links: [],
      count: 0
    };
  },
    computed: {
    ...mapGetters('account', {
      plan: 'plan'
    })
  },
  methods: {
    formatFn: function(value) {
      var d = moment.tz(value,"UTC")
      return d.tz(moment.tz.guess()).format("MMM Do, YYYY HH:mm");
    },
    async loadLinks() {
      this.isLoading = true;
      let res = await Vue.axios.get('/links', {
        params: this.params
      });
      this.links = res.data.links;
      this.count = res.data.count;
      this.isLoading = false;
    },
    newLink(){
      if (this.plan.balance == 0) {
        this.$modal.show(SubscriptionModal, {
                finishedCallback: () => {
                // refresh credits
                },
                copy: "You have exceeded your quota of codes"
            }, {
                adaptive: true,
                minHeight: 400,
                height: 'auto',
                scrollable: false
            })
      } else {
      this.$modal.show(NewDigitalLink, {
        finishedCallback: () => {
          this.loadLinks()
        }
      }, {
        adaptive: true,
        minHeight: 600,
        scrollable: true
      });}
    },
    openLink(params) {
      this.$router.push({ name: 'link', params: { id: params.row.id } })
    },
    updateParams(newProps) {
      this.params = Object.assign({}, this.params, newProps);
    },
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadLinks()
    },
    onPerPageChange(params) {
      this.updateParams({limit: params.currentPerPage});
      this.loadLinks()
    },
    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.loadLinks();
    },
    onSearch: _.debounce(function(params) {
      if (params.searchTerm.length == 0) {
        this.updateParams({
          page: 1,
          search: ''
        })
        this.loadLinks()
      } else if (params.searchTerm.length < 3) {
        return;
      }
      this.updateParams({search: params.searchTerm});
      this.loadLinks();
    }, 200)
  },
  created() {
    this.loadLinks().then(() => {
      this.loaded = true;
    }).catch((err) => {
      bugsnagClient.notify(err);
      this.$notify({
        type: 'error',
        title: 'Failed to retrieve dynamic links.',
        text: 'Please try again later.'
      });
    });
  }
}
</script>
