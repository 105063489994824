<template>
  <div class="password-modal">
    <div class="header">
      <h3>Change Password</h3>
    </div>
    <div class="container">
      <div class="columns">
        <div class="column col-12">
          <div v-show="error" class="toast toast-error">
            Failed to change password
          </div>
          <div class="form-group">
            <label class="form-label" for="currentPassword">Current Password</label>
            <input class="form-input" type="password" id="currentPassword" v-model="currentPassword">
          </div>

          <ValidationProvider name="password" rules="required" v-slot="{ errors }">
            <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
              <label class="form-label" for="input-password">New Password</label>
              <input class="form-input" id="input-password"  v-model="newPassword" name="input-password" type="password">
              <p class="form-input-hint">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
          <div class="form-group" v-bind:class="{ 'has-error': newPassword != confirmPassword }">
            <label class="form-label" for="input-confirmpassword">Confirm New Password</label>
            <input class="form-input" id="input-confirmpassword"  v-model="confirmPassword" type="password">
            <p v-show="newPassword != confirmPassword" class="form-input-hint">Passwords do not match.</p>
          </div>
          <br />
          <div v-if="isLoading" class="loading loading-lg"></div>
          <div v-else>
            <o-button class="col-4" @click.native="change" v-bind:disabled="newPassword != confirmPassword || newPassword == '' ">Change Password</o-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ArrowRightCircleIcon } from 'vue-feather-icons';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

import bugsnagClient from '../../utils/bugsnag';

export default {
  name: 'ChangePasswordModal',
  props: ['finishedCallback'],
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      isLoading: false,
      error: null
    }
  },
  components: {
    ArrowRightCircleIcon, ValidationProvider
  },
  mounted() {
  },
  methods: {
    change: function(){
      this.$http.post(`/changepassword`, {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword
      }).then((res) => {
        if (this.finishedCallback) this.finishedCallback();
        this.$emit('close');
      }).catch((err) => {
        bugsnagClient.notify(err);
        this.$notify({
          type: 'error',
          title: 'Failed to change password.',
          text: 'Please try again later.'
        })
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .password-modal {
    padding: 16px;
  }
  .button-tool {
    width: 100%;
  }
  .total-wrapper {
    padding-top:16px;
    padding-bottom:16px;
    float: right;
  }
  .total {
    font-size: 16px;
    margin-right: 8px;
  }
</style>
