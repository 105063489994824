<template>
  <div class="container grid-lg">
    <modals-container>
    </modals-container>
    <div class="columns">
      <div class="column col-12">
        <div class="card">
          <div class="content" v-if="loaded">
            <div class="columns">
              <div class="col-12 button-top">
                <button class="button-tool" @click="newBatch"><plus-circle-icon class="icon"></plus-circle-icon> New Batch</button>
              </div>
            </div>
          </div>
          <div v-if="!loaded" class="loading loading-lg"></div>
          <div v-else>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              :columns="columns"
              :totalRows="count"
              :rows="batches"
              :search-options="{
                enabled: true,
                placeholder: 'Search Batches'
              }"
              :pagination-options="{
                enabled: true
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: {field: 'updatedAt', type: 'desc'}
              }"
              :isLoading.sync="isLoading"
              @on-sort-change="onSortChange"
              @on-row-click="openBatch"
              @on-search="onSearch"
            >
              <template slot="table-row" slot-scope="props">
                {{props.formattedRow[props.column.field]}}
              </template>
              <div slot="emptystate">
                No batches found.
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const _ = require('lodash');
import Vue from 'vue';
import { PlusCircleIcon } from 'vue-feather-icons';

import bugsnagClient from '../utils/bugsnag';

import NewBatchModal from './modals/NewBatchModal';

import moment from 'moment-timezone';

export default {
  name: 'Batches',
  components: {
    PlusCircleIcon
  },
  data() {
    return {
      columns: [
        {
          label: 'Batch Reference',
          field: 'batchRef'
        },
        {
          label: 'SKU',
          field: 'sku'
        },
        {
          label: 'Created On',
          field: 'createdAt',
          type: 'date',
          formatFn: this.formatFn,
        },
        {
          label: 'Updated At',
          field: 'updatedAt',
          type: 'date',
          formatFn: this.formatFn,
        }
      ],
      loaded: false,
      params: {
        page: 1,
        limit: 10,
        search: '',
        sort: {
          field: '', 
          type: '',
        }
      },
      isLoading: false,
      batches: [],
      count: 0
    };
  },
  methods: {
    formatFn: function(value) {
      var d = moment.tz(value,"UTC")
      return d.tz(moment.tz.guess()).format("MMM Do, YYYY HH:mm");
    },
    newBatch(){
      this.$modal.show(NewBatchModal, {
        finishedCallback: (id) => {
          this.$router.push({
            name: 'batch', params: {id: id},
            query: {
              show: true
            }
          });
        }
      }, {
        adaptive: true,
        minHeight: 600,
        scrollable: true,
        height: "auto"
      })
    },
    async loadBatches()  {
      this.isLoading = true;
      let res = await Vue.axios.get('/batches', {params: this.params});
      this.batches = res.data.batches;
      this.count = res.data.count;
      this.isLoading = false;
    },
    openBatch(params) {
      this.$router.push({ name: 'batch', params: { id: params.row.id } })
    },
    updateParams(newProps) {
      this.params = Object.assign({}, this.params, newProps);
    },
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadBatches()
    },
    onPerPageChange(params) {
      this.updateParams({limit: params.currentPerPage});
      this.loadBatches()
    },
    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        }
      });
      this.loadBatches();
    },
    onSearch: _.debounce(function(params) {
      if (params.searchTerm.length == 0) {
        this.updateParams({
          page: params.page = 1,
          searchTerm: params.searchTerm = ''
        });
        this.loadBatches();
      } else if (params.searchTerm.length < 3) {
        return;
      }
      this.updateParams({search: params.searchTerm});
      this.loadBatches();
    }, 200)
  },
  created() {
    this.loadBatches().then(() => {
      this.loaded = true;
    }).catch((err) => {
      this.isLoading = false;
      bugsnagClient.notify(err);
      this.$notify({
          type: 'error',
          title: 'Failed to retrieve batches.',
          text: 'Please try again later.'
      });
    });
  }
}
</script>
