<template>
<div>
  <div v-if="creating" class="loading-cover">
    <div>
    <div class="loading loading-lg"></div>
    <span>Creating Smart Codes...</span>
    </div>
  </div>
  <div class="batch-modal">
    <div class="header">
      <h3>New Smart Code/s</h3>
    </div>
    <form>
      <div class="form-group">
        <label class="form-label" for="batchRef">Batch Reference <tip-modal title="Batch Reference" text="A batch reference is an identifier for a specific batch of a given product. This should be used to internally distinguish between different batches of the same product"></tip-modal></label>
        <input class="form-input" type="text" id="batchRef" v-model="batch.batchRef">
      </div>
      <div class="form-group" v-if="selectedTemplate">
        <div class="form-group">
          <label class="form-switch">
            <input type="checkbox" v-model="batch.linksEnabled" v-bind:disabled="selectedTemplate.gtin == null">
            <i class="form-icon"></i> <div>Generate GS1 Digital Links (requires GTIN)</div>
          </label>
          <p>What is a GS1 Digital Link? <tip-modal title="GS1 Digital Link" text="A GS1® Digital Link is a new standard from GS1 designed to improve upon existing GTIN(EAN/UPC) barcodes. <a target='_blank' href='https://www.gs1.org/standards/gs1-digital-link'>Click here for more information</a>."></tip-modal></p>
        </div>
        <div v-for="(attribute, attributeKey) in selectedTemplate.attributes" :key="attributeKey">
          <label class="form-label" :for="attributeKey+'_attribute'">{{attributeKey}}</label>

          <input class="form-input" v-if="attribute.attributeType == 'text'" type="text" :id="attributeKey+'_attribute'" v-model="batch.attributes[attributeKey]">
          <file-selector v-if="attribute.attributeType == 'file'" :maxFiles=1 v-bind:files="batch.attributes[attributeKey]" v-on:update="(file) => {batch.attributes[attributeKey] = file}"></file-selector>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label" for="count">Number of Smart Codes</label>
        <input class="form-input" id="count" v-model.number="batch.atomCount" type="number">
        <div v-if="plan">
          <div v-if="batch.atomCount > plan.balance" class="toast" style="height: 54px;line-height: 32px">
            You need to upgrade your subscription to create this batch
            <div style="float:right">
            <o-button buttonStyle="alt" @click.native="purchase">Upgrade</o-button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="batch-footer">
      <button class="button-tool" @click="newBatch" v-bind:disabled="!completed()">Create Smart Code/s <arrow-right-circle-icon class="icon"></arrow-right-circle-icon></button>
    </div>
  </div>

  <div class="batch-helper">
    <div class="container">
      <div class="columns">
        <div class="column col-2">
          <info-icon></info-icon>
        </div>
        <div class="column col-10">
          <p>A batch is a grouping of Smart Codes.</p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
const _ = require('lodash');

import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { VueAutosuggest } from 'vue-autosuggest';
import { ArrowRightCircleIcon, InfoIcon } from 'vue-feather-icons';

import bugsnagClient from '../../utils/bugsnag';

import FileSelector from '../../components/FileSelector';
import SubscriptionModal from './SubscriptionModal';

import TipModal from '../../components/Tip';

export default {
  name: 'NewCodesModal',
  props: ['finishedCallback', 'template'],
  data() {
    return {
      batch: {
        batchRef: '',
        templateId: '',
        atomCount: 0,
        linksEnabled: false,
        attributes: {}
      },
      creating: false,
      selectedTemplate: null
    }
  },
  components: {
    ArrowRightCircleIcon, InfoIcon,
    VueAutosuggest, FileSelector, TipModal
  },
  mounted() {
    this.loadPlan().catch((err) => {
      bugsnagClient.notify(err);
      this.$notify({
        type: 'error',
        title: 'Failed to load plan information.',
        text: 'Please try again later.'
      });
    })
    this.selectedTemplate = this.template;
    this.batch.templateId = this.template.id;
    this.batch.attributes = _.mapValues(this.template.attributes, (attribute) => {
      return attribute.defaultVal;
    });
  },
  computed: {
    ...mapGetters('account', {
      plan: 'plan'
    })
  },
  methods: {
    ...mapActions('account', [
      'loadPlan'
    ]),
    newBatch() {
      this.creating = true;
      this.$http.post(`/batches`, {
        ...this.batch
      }).then((res) => {
        this.creating = false;
        if (this.finishedCallback) this.finishedCallback(res.data.id);
        this.$emit('close')
      }).catch((err) => {
        this.creating = false;
        bugsnagClient.notify(err);
        this.$notify({
          type: 'error',
          title: 'Failed to create batch.',
          text: 'Please try again later.'
        });
      })
    },
    purchase() {
      this.$modal.show(SubscriptionModal, {
        finishedCallback: () => {
        // refresh credits
        }
      }, {
        adaptive: true,
        minHeight: 400,
        height: 'auto',
        scrollable: false
      })
    },
    completed: function(){
      if (!this.batch && !this.plan) return false;
      if (this.batch.batchRef != '' && this.batch.templateId != '' && this.batch.atomCount > 0){
        if (this.batch.atomCount <= this.plan.balance){
          return true
        }
      }
      return false;
    }
  }
}
</script>
<style lang="scss" scoped>
  .batch-modal {
    padding: 16px;
    padding-bottom: 148px;
  }
  .batch-footer {
    float:right;
    margin-right: 0px;
    padding-top: 24px;

    .button-tool {
      float:right;
      margin: 0px;
      z-index: 9999999;
    }
    .footer-text {
      display: inline-block;
      margin: 16px;
    }
    margin-bottom: 4px;
  }
  .batch-helper {
    background-color: #efefef;
    padding: 16px 16px 0px 8px;
    bottom: 0;
    position: absolute;
    width: 100%
  }
</style>
