<template>
    <div class="container grid-lg">
        <modals-container>
        </modals-container>
        <div class="columns">
            <div class="column col-12">
                <analytics-search-bar :filters="filters" :getAnalytics="getAnalytics"></analytics-search-bar>
            </div>
            <div v-if="plan.tier == 'free'" class="column col-12">
            <div class="card search">
    <div class="content">
        <div class="container">
            <div class="columns">
                <div class="col-7 col-md-12 column">
                <h5>Hey there!</h5>
                    <h8>To see more detailed analytics, you need to upgrade to a Counterset Team or Enterprise account!</h8>
                </div>
                <div class="col-3 col-md-12 column">
                </div>
                <div class="col-2 col-md-12 column">
                    <div class="searchHolder">
                        <o-button @click.native="upgradecta">Upgrade</o-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
            </div>
            <div class="column col-12">
                <div id="map-holder" class="card">
                    <div v-if="!loaded" class="loading loading-lg"></div>
                    <analytics-map-view v-else :disabled="plan.tier == 'free'" :scans="scans"></analytics-map-view>
                </div>
            </div>
        </div>

            <div class="column col-12">
                <div class="card">
                    <div class="content">
                        <div class="tools">
                            <o-button @click.native="exportAnalytics()">Export Analytics</o-button>
                        </div>
                    </div>
                    <div>
                        <apexchart type=line height=250 :options="scanChartOptions" :series="groupedScans" ></apexchart>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
let _ = require('lodash')
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex';

import AnalyticsSearchBar from '../components/analytics/AnalyticsSearchBar';
import AnalyticsMapView from '../components/analytics/AnalyticsMapView';

import ExportModal from './modals/ExportModal';
import SubscriptionModal from './modals/SubscriptionModal';

import bugsnagClient from '../utils/bugsnag';

export default {
    name: 'Analytics',
    components: {
        AnalyticsSearchBar,
        AnalyticsMapView
    },
    data() {
        return {
            scanChartOptions: {
                chart: {
                    height: 250,
                    type: 'line',
                    shadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 1
                    },
                    toolbar: {
                        show: true
                    }
                },
                colors: ['#77B6EA', '#545454'],
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 6
                },
                xaxis: {
                    type: 'datetime'
                },
                yaxis: {
                    title: {
                        text: 'Scans'
                    },
                    min: 0
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -5,
                    offsetX: -5
                }
            },
            scans: [],
            groupedScans: [],
            loaded: false,
            filters: [{
                label: 'Any Scans',
                filter: 'any'
            }]
        };
    },
    mounted() {
        let atomId = this.$route.query.atomid;
        let batchId = this.$route.query.batchid;
        let linkId = this.$route.query.linkid;
        if (atomId) {
            this.filters = [{
                label: 'Individual Code',
                filter: 'atom',
                val: atomId
            }];
            this.getAnalytics({
                filters: this.filters
            });
        } else if (batchId) {
            this.filters = [{
                label: 'Batch Reference',
                filter: 'batch',
                val: batchId
            }];
            this.getAnalytics({
                filters: this.filters
            });
        } else if (linkId) {
            this.filters = [{
                label: 'Link ID',
                filter: 'link',
                val: linkId.toString()
            }];
            this.getAnalytics({
                filters: this.filters
            });
        } else {
            this.getAnalytics();
        }
    },
    beforeRouteUpdate (to, from, next) {
        if (to.query.atomid) {
            this.filters = [{
                label: 'Individual Code',
                filter: 'atom',
                val: to.query.atomid
            }];
            this.getAnalytics({
                filters: this.filters
            });
        } else {
            this.getAnalytics();
        }
    },
    computed: {
    ...mapGetters('account', {
      plan: 'plan'
    })
  },
    methods: {
        ...mapActions('notifications', [
          'populateNotifications'
        ]),
        upgradecta(){
            this.$modal.show(SubscriptionModal, {
                finishedCallback: () => {
                // refresh credits
                },
            }, {
                adaptive: true,
                minHeight: 400,
                height: 'auto',
                scrollable: false
            })
        },
        getAnalytics (params) {
            this.loaded = false
            this.$http.post('/scans', {
                ...params
            }).then((res) => {
                this.scans = _.filter(res.data.scans, function (scan) {
                    if (_.isNil(scan.geog)) return false;
                    return true;
                });
                let groupedByDate = _.groupBy(res.data.scans, function (scan) {
                    return moment(scan.createdAt).startOf('day').format();
                });
                this.groupedScans = [{
                    data: _.map(groupedByDate, function(scans, dateKey){
                        return {
                            x: dateKey,
                            y: scans.length
                        };
                    })
                }];
                this.loaded = true;
            }).catch(err => {
                this.loaded = true;
                this.scans = [];
                this.groupedScans = [{
                    data: []
                }];
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to retrieve analytics.',
                    text: 'Please try again later.'
                })
            })
        },
        exportAnalytics() {
            if (this.plan.tier != 'pro_m' && this.plan.tier != 'pro_a' && this.plan.tier != 'starter_a' && this.plan.tier != 'starter_m')
            {
                this.$modal.show(SubscriptionModal, {
                finishedCallback: () => {
                // refresh credits
                },
                copy: "You need to be a Pro or Teams member to export analytics"
            }, {
                adaptive: true,
                minHeight: 400,
                height: 'auto',
                scrollable: false
            })
            } else {
            this.$modal.show(ExportModal, {
                data: this.scans,
                exportType: 'analytics',
                finishedCallback: () => {
                }
            }, {
                adaptive: true,
                minHeight: 200,
                scrollable: false
            })
        }},
        openScanDetail(id){
            console.log("scan detail opened");
        },
    }
}
</script>
<style lang="scss" scoped>
    #map-holder {
        height: 500px;
        position: relative;
    }
    
.search {
    overflow: visible;
}

.filter {
    display: flex;
    flex-direction: row;
    width: 100%;
}

#filterSelect {
    min-width: 200px;
    padding-right: 16px;
}

.actions {
    flex-grow: 1;
    display: inline-flex;
    justify-content: flex-end;
}

.searchHolder {
    display: flex;
    flex-grow: 1;
    height: 100%;
    align-items: flex-end;
    display: block;
    padding-top: 24px;
}

</style>
