<template>
<div>
  <div class="download-modal">
      <div class="header">
        <h3>View/Download QR</h3>
        <div class="form-group" v-if="link">
            <label class="form-switch">
                <input type="checkbox" v-model="showGS1" v-bind:disabled="!link.gtin">
                <i class="form-icon"></i>GS1 Digital Link
            </label>
        </div>
      </div>
      <div class="download-modal-content container">
        <div class="columns">
            <div class="col-6 col-md-12">
                <div class="form-group">
                    <label class="form-label">QR</label>
                    <vue-qrcode :value="slugString(showGS1)" :options="{ width: 160 }"></vue-qrcode>
                </div>
            </div>
            <div class="col-6 col-md-12">
                <div class="form-group">
                    <label class="form-label">QR URI</label>
                    
                    <a :href="slugString(showGS1)" style="word-wrap: break-word">{{ slugString(showGS1) }}</a>
                    
                </div>
                <div class="form-group">
                    <label class="form-label">Download Links</label>
                    <p>
                        <button class="button-tool" @click="downloadQRPNG"><download-icon class="icon"></download-icon> QR (PNG)</button>
                        <button class="button-tool" @click="downloadQRSVG"><download-icon class="icon"></download-icon> QR (SVG)</button>
                    </p>
                </div>
            </div>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
import Vue from 'vue';
import QRCode from 'qrcode';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import Download from 'downloadjs';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { ArrowRightCircleIcon, InfoIcon, DownloadIcon } from 'vue-feather-icons';

import bugsnagClient from '../../utils/bugsnag';

export default {
  name: 'DownloadQRModal',
  props: ['link', 'atom'],
  components: {
    ArrowRightCircleIcon, InfoIcon, DownloadIcon, Download, 'vue-qrcode': VueQrcode
  },
  mounted() {

  },
  data() {
    return {
        showGS1: false
    }
  },
  methods: {
    slugString(gs1) {
        if (gs1) {
            if (this.link.gtin && this.link.batchRef) {
                return `${Vue.config.sulde}/01/${this.link.gtin}/10/${this.link.batchRef}`;
            } else if (this.link.gtin) {
                return `${Vue.config.sulde}/01/${this.link.gtin}`;
            }
        } else {
            if (this.link){
                return `${Vue.config.sulde}/l/${this.link.hashId}`;
            } else {
                return `${Vue.config.sulde}/p/${this.atom.slugId}`;
            }
            
        }
    },
    downloadQRPNG() {
        const opts = {
            errorCorrectionLevel: 'H',
            type: 'image/png',
            rendererOpts: {
                quality: 0.95
            }
        };
        let datauri = this.slugString(this.showGS1);
        QRCode.toDataURL(datauri, opts, (err, qrdata) => {
            if (err) {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to generate QR Code.',
                    text: 'Please try again later.'
                })
                return;
            }
            Download(qrdata, `qr-${moment().format()}.png`, 'image/png')
        });
    },
    downloadQRSVG() {
        let datauri = this.slugString(this.showGS1);
        QRCode.toString(datauri, (err, qrdata) => {
            if (err) {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to generate QR Code.',
                    text: 'Please try again later.'
                })
                return;
            }
            Download(qrdata, `qr-${moment().format()}.svg`, 'image/svg')
        });
    }
  }
}
</script>
<style lang="scss" scoped>
  .download-modal {
      padding: 16px;
  }
  .download-footer {
    margin-right: 0px;
    padding-top: 24px;

    .button-tool {
      margin: 0px;
      z-index: 9999999;
    }
    .footer-text {
      display: inline-block;
      margin: 16px;
    }
    margin-bottom: 4px;
  }
  .download-helper {
      background-color: #efefef;
      padding: 16px 16px 0px 8px;
      bottom: 0;
      position: absolute;
      width: 100%
  }
  .download-modal-content {
      padding-top: 16px;
  }
</style>
