import Vue from 'vue';
import { wrapRequest } from '../../utils/helpers';
const _ = require('lodash');

const state = {
    identity: null,
    organization: null,
    plan: null,
    invoices: [],
    paymentMethod: null,
    apikeys: []
};

const getters = {
    identity: (state) => {
        return state.identity;
    },
    organization: (state) => {
        return state.organization;
    },
    plan: (state) => {
        let plan = state.plan;
        return plan;
    },
    invoices: (state) => {
        return state.invoices;
    },
    paymentMethod: (state) => {
        return state.paymentMethod;
    },
    apikeys: (state) => {
        return state.apikeys;
    }
};

const mutations = {
    setIdentity: (state, identity) => {
        state.identity = identity;
    },
    setOrganization: (state, organization) => {
        state.organization = organization;
    },
    setPlan: (state, plan) => {
        state.plan = plan;
    },
    setInvoices: (state, invoices) => {
        state.invoices = invoices
    },
    setPaymentMethod: (state, paymentMethod) => {
        state.paymentMethod = paymentMethod
    },
    setAPIKeys: (state, apikeys) => {
        state.apikeys = apikeys
    }
};

const actions = {
    loadIdentity: wrapRequest(async ({ commit, state }) => {
        let res = await Vue.axios.get('/identity', {
        });
        commit('setIdentity', res.data)
    }),
    loadOrganization: wrapRequest(async ({ commit, state }) => {
        let res = await Vue.axios.get('/organization', {
        });
        commit('setOrganization', res.data)
    }),
    loadPlan: wrapRequest(async ({ commit, state }) => {
        let res = await Vue.axios.get('/plan', {
        });
        commit('setPlan', res.data)
    }),
    loadInvoices: wrapRequest(async ({ commit, state }) => {
        let res = await Vue.axios.get('/invoices', {
        });
        commit('setInvoices', res.data)
    }),
    loadPaymentMethod: wrapRequest(async ({ commit, state }) => {
        let res = await Vue.axios.get('/payment', {
        });
        commit('setPaymentMethod', res.data)
    }),
    loadAPIKeys: wrapRequest(async ({ commit, state }) => {
        let res = await Vue.axios.get('/apikeys', {
        });
        commit('setAPIKeys', res.data)
    }),
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};