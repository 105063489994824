<template>
    <div class="container grid-lg">
        <modals-container>
        </modals-container>
        <div class="columns">
            <div class="column col-12">
                <div class="card">
                    <div class="content">
                        <div class="link-header">
                            <button class="button-tool" @click="back"><arrow-left-circle-icon class="icon"></arrow-left-circle-icon> Links</button>
                            <div class="export">
                                <button class="button-tool" @click="archiveLink"><trash-2-icon class="icon"></trash-2-icon> Archive</button>
                                <button class="button-tool" @click="openAnalytics"><bar-chart-2-icon class="icon"></bar-chart-2-icon> View Analytics</button>
                                <button class="button-tool" @click="downloadQR"><download-icon class="icon"></download-icon> Download/View QR</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column col-12">
                <div class="card">
                    <div class="content">
                        <div v-if="!loaded" class="loading loading-lg"></div>
                        <div v-else>
                            <div class="link-properties">
                                <div class="link-title">
                                    <h5>Link Configuration</h5>
                                    <o-button :loading="savingLink" :disabled="!modified" @click.native="saveLink">Save Link Configuration</o-button>
                                </div>
                                <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                    <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
                                        <label class="form-label" for="name">Name</label>
                                        <input class="form-input" type="text" id="name" v-model="link.name" name="name">
                                        <p class="form-input-hint">{{ errors[0] }}</p>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider name="gtin" :rules="{ regex: /^[0-9]{14}$|^[0-9]{13}$|^[0-9]{12}$|^[0-9]{8}$/ }" v-slot="{ errors }">
                                    <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
                                        <label class="form-label" for="gtin">GTIN (UPC/EAN)</label>
                                        <input class="form-input" type="text" id="gtin" v-model="link.gtin" name="gtin">
                                        <p class="form-input-hint">{{ errors[0] }}</p>
                                    </div>
                                </ValidationProvider>
                                <div class="form-group">
                                    <label class="form-label" for="batchRef">Batch Reference</label>
                                    <input class="form-input" type="text" v-bind:disabled="this.link.gtin == ''" id="batchRef" v-model="link.batchRef">
                                </div>
                                <div class="form-group">
                                    <p>
                                        Adding a GTIN and/or Batch reference will automatically create a GS1 Digital Link. 
                                    </p>
                                </div>
                                <ValidationProvider name="uri" rules="required|url" v-slot="{ errors }">
                                    <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
                                        <label class="form-label" for="uri">Link Destination (Default)</label>
                                        <input class="form-input" type="text" id="uri" v-model="link.uri" name="uri">
                                        <p class="form-input-hint">{{ errors[0] }}</p>
                                    </div>
                                </ValidationProvider>
                                <div class="form-group">
                                    <p>
                                        If none of the redirect rules match an incoming user's request, this URL will be opened.
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="redirect-rules">Redirect Rules</label>
                                    <draggable tag="ul" :list="link.rules" class="list-group" handle=".handle">
                                        <li class="rule"
                                            v-for="(rule, ruleIdx) in link.rules"
                                            v-bind:key="'rule'+ruleIdx">

                                            <div class="link-title">
                                                <o-button buttonStyle="icon" class="handle"><move-icon></move-icon></o-button>
                                                <o-button buttonStyle="icon"  @click.native="removeRule(ruleIdx)"><minus-circle-icon></minus-circle-icon></o-button>
                                            </div>
                                            <ValidationProvider name="uri" rules="required|url" v-slot="{ errors }">
                                                <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
                                                    <label class="form-label" for="rule-uri">Link Destination <tip-modal title="Rules" text="If all conditions are met, a user will be sent to this link destination"></tip-modal></label>
                                                    <input class="form-input" type="text" id="rule-uri" :name="'uri-'+ruleIdx" v-model="rule.uri">
                                                    <p class="form-input-hint">{{ errors[0] }}</p>
                                                </div>
                                            </ValidationProvider>

                                            <div class="form-group">
                                                <label class="form-label" for="rule-conditions">Conditions</label>
                                                <li
                                                    v-for="(condition, conditionIdx) in rule.conditions"
                                                    :key="conditionIdx">
                                                    <div class="condition">

                                                        <div class="form-group">
                                                            <label class="form-label" for="condition-condition">Condition <tip-modal title="Conditions" text="Conditions are rules which are used to decide which link a user is directed to"></tip-modal></label>
                                                            <select class="form-select" id="condition-condition" v-model="condition.condition">
                                                                <option @click="clearCondition(condition)" value="geo">Geo Target (Country)</option>
                                                                <option @click="clearCondition(condition)" value="locale">Language</option>
                                                                <option @click="clearCondition(condition)" value="os">Operating System</option>
                                                            </select>
                                                        </div>

                                                        <div class="form-group" v-if="condition.condition == 'locale'">
                                                            <label class="form-label" for="locale-selector">Language <tip-modal title="Language" text="Language conditions work by checking the browser locale. the conditions can be set generally (e.g. English, which applies to any of the english locales), or more specifically (e.g. English(Canada), which only applies to Canadian english locales)"></tip-modal></label>
                                                            <v-select :options="locales" v-model="condition.locale" :reduce="locale => locale.code" label="language"></v-select>
                                                        </div>

                                                        <div class="form-group" v-if="condition.condition == 'geo'">
                                                            <label class="form-label" for="geo-selector">Geo Target (Country) <tip-modal title="Geo Target (Country)" text="Geo Targeting uses IP address data to geolocate a user to a specific country. Geo Targeting accuracy is dependent on numerous factors, including whether the user is behind a VPN/Proxy."></tip-modal></label>
                                                            <v-select :options="countries" v-model="condition.country" :reduce="country => country.code" label="country"></v-select>
                                                        </div>

                                                        <div class="form-group" v-if="condition.condition == 'os'">
                                                            <label class="form-label" for="geo-selector">Target Operating System <tip-modal title="Operating System" text="Operating systems checks the browser's User Agent in order to determine the OS of the device used to access the link. this can be used to change link redirection between iPhone and Android play stores, for example."></tip-modal></label>
                                                            <v-select :options="os" v-model="condition.os" :reduce="os => os.code" label="os"></v-select>
                                                        </div>

                                                        <div>
                                                            <o-button buttonStyle="icon" @click.native="removeCondition(conditionIdx, rule)">
                                                                <minus-circle-icon></minus-circle-icon>
                                                            </o-button>
                                                        </div>
                                                    </div>
                                                </li>
                                                <o-button buttonStyle="primary" @click.native="addCondition(rule)"><plus-circle-icon></plus-circle-icon>  Add New Condition</o-button>
                                            </div>
                                        </li>
                                    </draggable>
                                    <div class="link-title">
                                        <o-button buttonStyle="primary" @click.native="addRule()"><plus-circle-icon></plus-circle-icon>  Add New Rule</o-button>
                                        <o-button :loading="savingLink" :disabled="!modified" @click.native="saveLink">Save Link Configuration</o-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import moment from 'moment';
import draggable from 'vuedraggable';
import { MoveIcon, InfoIcon, PlusCircleIcon, XCircleIcon, MinusCircleIcon, SaveIcon, ArrowLeftCircleIcon, ExternalLinkIcon, DownloadIcon, EditIcon, Trash2Icon, BarChart2Icon } from 'vue-feather-icons';
import { ValidationProvider, extend } from 'vee-validate';
import { required, regex } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('regex', {
  ...regex,
  message: 'This GTIN is invalid.'
});

extend('url', {
    validate: (str) => {
            var pattern = new RegExp(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,'i');
            return !!pattern.test(str);
    },
    message: 'This is not a valid URL (must start with http:// or https://'
})

import bugsnagClient from '../utils/bugsnag';

import ConfirmDialog from '../components/ConfirmDialog';
import TipModal from '../components/Tip'
import DownloadQRModal from './modals/DownloadQRModal';

import countries from '../countries.json';
import locales from '../locales.json';
import os from '../os.json';

export default {
    name: 'Link',
    components: {
        draggable, ValidationProvider,
        MoveIcon, InfoIcon, PlusCircleIcon, XCircleIcon, MinusCircleIcon, SaveIcon, ArrowLeftCircleIcon, ExternalLinkIcon, DownloadIcon, EditIcon, TipModal, Trash2Icon, BarChart2Icon
    },
    beforeRouteLeave (to, from, next) {
        if (this.modified){
            this.$modal.show(ConfirmDialog, {
                finishedCallback: (confirmed) => {
                    if (confirmed) {
                        next()
                    } else {
                        next(false)
                    }
                },
                message: 'You have unsaved changes! Are you sure you want to leave?',
                primaryButtonTitle: 'Leave'
            }, {
                height: 150,
                width: 300,
                scrollable: false
            })
        } else {
            next()
        }
    },
    mounted() {
        this.getLink();
    },
    watch: {
        link: {
            handler: function (val, oldVal){
                if (oldVal !== null) {
                this.modified = true
                }
            }, deep: true
        }
    },
    data() {
        return {
            id: this.$route.params.id,
            loaded: false,
            savingLink: false,
            modified: false,
            link: null,
            countries: countries,
            locales: locales,
            os: os
        };
    },
    methods: {
        back() {
            this.$router.push({ name: 'links' });
        },
        getLink () {
            this.$http.get(`/links/${this.id}`).then((linkRes) => {
                this.link = linkRes.data;
            }).then(() => {
                this.loaded = true
                if (this.$route.query.show == true) {
                    this.downloadQR();
                }
            }).catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to retrieve dynamic link.',
                    text: 'Please try again later.'
                })
            })
        },
        addRule() {
            if (!this.link.rules) {
                this.$set(this.link, 'rules', [])
            }
            this.link.rules.push({
                uri: "https://example.com",
                conditions: []
            });
        },
        removeRule(idx){
            this.link.rules.splice(idx, 1);
        },
        addCondition(rule) {
            rule.conditions.push({
            })
        },
        removeCondition(idx, rule) {
            rule.conditions.splice(idx, 1)
        },
        clearCondition(condition) {
            delete condition.locale;
            delete condition.country;
        },
        downloadQR() {
            this.$modal.show(DownloadQRModal, {
                link: this.link,
                finishedCallback: () => {
                }
            }, {
                adaptive: true,
                minHeight: 350,
                height: 'auto',
                scrollable: false
            })
        },
        saveLink(){
            if (this.modified == false)
            {
                return;
            }
            this.savingLink = true;
            this.$http.put(`/links/${this.id}`, {
                ...this.link
            }).then((linkRes) => {
                this.link = linkRes.data;
                this.savingLink = false;
                this.modified = false;
            }).catch((err) => {
                this.savingLink = false;
                this.modified = true;
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to save dynamic link.',
                    text: 'Please try again later.'
                });
            }).finally(() => {
                this.savingLink = false;
                this.modified = false;
            })
            
        },
        archiveLink(){
            this.$modal.show(ConfirmDialog, {
                finishedCallback: (confirmed) => {
                    if (confirmed) {
                        this.$http.delete(`/links/${this.id}`)
                        .then(() => {
                            this.$router.push({ name: 'links' });
                        }).catch((err) => {
                            bugsnagClient.notify(err);
                            this.$notify({
                                type: 'error',
                                title: 'Failed to archive dynamic link.',
                                text: 'Please try again later.'
                            })
                        })
                    }
                },
                message: 'Are you sure you want to archive this link?',
                primaryButtonTitle: 'Archive Link'
            }, {
                height: 140,
                width: 300,
                scrollable: false
            })
        },
        openAnalytics(){
            this.$router.push({ name: 'analytics', query: { linkid: this.id } });
        }
    }
}


</script>
<style lang="scss" scoped>
.card {
    overflow: visible !important;
}
.link-header {
    h2 {
       display: inline-block;
       margin-left: 16px;
    }
    .close {
       float:right;
       margin: 16px;
    }
    margin-bottom: 4px;
    .export {
        float:right;
    }
}
.link-title {
    display: flex;
    justify-content: space-between;
}
.link-info {
    margin-top: 16px;
    float: left;
}
.link-qr {
    float: right;
}
.button-tool {
    text-align: left;
    margin-bottom: 8px;
}
.tools {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.list-group {
    list-style: none;
}
.rule {
    background-color: rgb(241, 241, 241);
    border-radius: 4px;
    box-shadow: 0 7px 14px 0 rgba(59,65,94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07);
    margin-bottom: 12px;
    padding: 12px;
}
.condition {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(164, 184, 196);
    border-radius: 4px;
    box-shadow: 0 7px 14px 0 rgba(59,65,94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07);
    padding: 4px;
    .form-group {
        flex-grow: 1;
        padding: 16px;
    }
    .map-group {
        padding: 16px;
        flex-grow: 2;
    }
    margin-bottom: 12px;
}
.dropdown {
    z-index: 99 !important;
}

.v-select {
    background: #ffffff;
}
</style>
