<template>
<div class="card search">
    <div class="content">
        <div class="container">
            <div class="columns">
                <div class="col-7 col-md-12 column">
                    <h5>Filters</h5>
                    <div class="filter" v-for="(filter, idx) in filters" v-bind:key="filter.filter">
                        <v-select 
                            label="label" 
                            id="filterSelect" 
                            :options="availableFilterOptions" 
                            v-model="filters[idx]" 
                            :clearable="false"
                            ></v-select>
                        <div v-if="filter.filter == 'batch'">
                            <input class="form-input" type="text" id="input-batch" placeholder="Batch Ref" v-model="filter.val">
                        </div>
                        <div v-if="filter.filter == 'sku'">
                            <input class="form-input" type="text" id="input-sku" placeholder="Search SKU" v-model="filter.val">
                        </div>
                        <div v-if="filter.filter == 'atom'">
                            <input class="form-input" type="text" id="input-atomid" placeholder="Code Slug ID" v-model="filter.val">
                        </div>
                        <div v-if="filter.filter == 'link'">
                            <input class="form-input" type="text" id="input-atomid" placeholder="Search Link ID" v-model="filter.val">
                        </div>
                        <div class="actions">
                            <o-button buttonStyle="icon" @click.native="addFilter()"><plus-circle-icon></plus-circle-icon></o-button>
                            <o-button buttonStyle="icon" @click.native="removeFilter(idx)" v-if="filters.length > 1"><trash-2-icon></trash-2-icon></o-button>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-md-12 column">
                    <h5>Date Range</h5>
                    <VueCtkDateTimePicker 
                        v-model="scanRange" 
                        format="YYYY-MM-DDTHH:mm:ss.sssZ"
                        formatted="ll"
                        inputSize="sm"
                        label="Select date range"
                        :noLabel="true"
                        :range="true"
                        :maxDate="maxDate"
                    />
                </div>
                <div class="col-2 col-md-12 column">
                    <div class="searchHolder">
                        <o-button @click.native="search">Search</o-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
const _ = require('lodash');
import Vue from 'vue';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { PlusCircleIcon, Trash2Icon } from 'vue-feather-icons';

import bugsnagClient from '../../utils/bugsnag';


export default {
  name: 'AnalyticsSearchBar',
  components: {
    PlusCircleIcon, Trash2Icon
  },
  props: {
    getAnalytics: {
      default: (params) => {},
      type: Function
    },
    filters: {
      default: () => { return [{
        label: 'Any Scans',
        filter: 'any'
      }] },
      type: Array
    }
  },
  data() {
    return {
      filterOptions: [
        {
          label: 'Any Scans',
          filter: 'any',
          val: ''
        },
        {
          label: 'SKU',
          filter: 'sku',
          val: ''
        },
        {
          label: 'Batch Reference',
          filter: 'batch',
          val: ''
        },
        {
          label: 'Individual Code',
          filter: 'atom',
          val: ''
        },
        {
          label: 'Link ID',
          filter: 'link',
          val: ''
        }
      ],
      scanRange: {
        start: moment().subtract(1, 'months').toDate(),
        end: moment().add(1, 'days').toDate()
      },
      maxDate: moment().add(1, 'days').toISOString()
    }
  },
  computed: {
    availableFilterOptions(){
      return _.differenceBy(this.filterOptions, this.filters, 'filter');
    }
  },
  methods: {
    addFilter() {
      this.filters.push({
        label: 'Any Scans',
        filter: 'any'
      });
    },
    removeFilter(idx) {
      this.filters.splice(idx, 1);
    },
    search(){
      this.getAnalytics({
        fromDate: this.scanRange.start,
        toDate: this.scanRange.end,
        filters: this.filters
      }).catch((err) => {
        bugsnagClient.notify(err);
        this.$notify({
          type: 'error',
          title: 'Failed to retrieve analytics for time period.',
          text: 'Please try again later.'
        })
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.search {
    overflow: visible;
}

.filter {
    display: flex;
    flex-direction: row;
    width: 100%;
}

#filterSelect {
    min-width: 200px;
    padding-right: 16px;
}

.actions {
    flex-grow: 1;
    display: inline-flex;
    justify-content: flex-end;
}

.searchHolder {
    display: flex;
    flex-grow: 1;
    height: 100%;
    align-items: flex-end;
}
</style>
