const _ = require('lodash');

// export default {
//     request: function (req, token) {
//         //console.log(JSON.stringify(token));
//         // this.options.http._setHeaders.call(this, req, {Authorization: 'Bearer ' + token});
//         this.drivers.http.setHeaders.call(this, req, {
//             Authorization: 'Bearer ' + token
//           });
//     },
//     response: function (res) {
        // if (_.isString(res.data.accessToken)){
        //     return res.data.accessToken;
        // }
//     }
// }

var bearer = {
    request: function (req, token) {
      this.http.setHeaders.call(this, req, {
        Authorization: 'Bearer ' + token
      });
    },
    response: function (res) {
        if (_.isString(res.data.accessToken)){
            return res.data.accessToken;
        }
    }
  };
  
  module.exports = bearer;