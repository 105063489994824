<template>
  <div class="confirmDialog">
    <div v-if="!loaded">
      <h4>Generating Proof</h4>
      <div class="loading loading-lg"></div>
    </div>
    <div v-else>
      <div class="proof" v-if="proof.verified">
        <h4>Proof Generated!</h4>
        <h5>Successfully verified</h5>
        <check-circle-icon size="2x"></check-circle-icon>
        <p>Calculated Digest: {{proof.digest}}</p>
      </div>
      <div v-else>
        <h4>Proof Failure</h4>
        <h6>Failed to generate proof</h6>
        <p>Please contact us.</p>
      </div>
    </div>
      
    <div class="buttonHolder">
      <o-button buttonStyle="alt" @click.native="close()">{{!loaded ? 'Cancel' : 'Close'}}</o-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { CheckCircleIcon } from 'vue-feather-icons';

import bugsnagClient from '../utils/bugsnag';

import OButton from './OButton';

export default {
  name: 'ProofDialog',
  data() {
    return {
      loaded: false,
      proof: null
    }
  },
  props: {
    operationId: {
      type: String
    },
    finishedCallback: {
      default: () => {}
    },
  },
  components: {
    OButton, CheckCircleIcon
  },
  mounted() {
    this.generateProof();
  },
  methods: {
    close() {
      if (this.finishedCallback) this.finishedCallback(this.loaded);
      this.$emit('close')
    },
    async generateProof(){
      try {
        var proofRes = await this.$http.post(`/prove/${this.operationId}`)
        this.proof = proofRes.data;
        this.loaded = true;
      } catch (err) {
        this.proof = {verified: false}
        this.loaded = true;
        bugsnagClient.notify(err);
        this.$notify({
          type: 'error',
          title: 'Failed to generate proof.',
          text: 'Please try again later.'
        });
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .confirmDialog {
    padding: 16px;
    justify-content: center;
    display: flex;
    font-size: 14px;

    p {
      overflow-wrap: anywhere;
    }
  }
  .proof {
    word-break: break-word;
  }
  .buttonHolder {
    position: absolute;
    bottom: 16px;
    display: flex;
    right: 16px;
    .obutton {
      margin-left: 8px;
    }
  }
</style>
