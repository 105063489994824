<template>
<div class="container grid-xl">
  <notifications position="top center" />
  <router-view></router-view>
</div>
</template>
<script>
import './assets/scss/main.scss';
import 'vue-good-table/dist/vue-good-table.css';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'vue-select/dist/vue-select.css';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default {
  name: 'app',
  components: { },
  created(){
  },
  methods: {
    logout: function(e){
      this.$auth.logout({
        redirect: '/login'
      });
    }
  }
}
</script>
<style lang="scss" scoped>
#content {
    overflow-y: auto;
}
</style>