import Vue from 'vue';
const _ = require('lodash');

const state = {
    items: []
};

const getters = {
    files: (state) => {
        return _.reverse(state.items);
    }
};

const mutations = {
    setFiles: (state, items) => {
        state.items = items;
    }
};

const actions = {
    loadFiles: async ({ commit, state }) => {
        let res = await Vue.axios.get('/files', {
        });
        commit('setFiles', res.data)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};