import Vue from 'vue';
const _ = require('lodash');

const state = {
    items: []
};

const getters = {
    batches: (state) => {
        return _.reverse(state.items);
    },
    skus: (state) => {
        return _.filter(_.map(state.items, (batch) => {
            return {
                item: batch.sku,
                val: batch.sku
            }
        }), 'val');
    },
    batchRefs: (state) => {
        return _.filter(_.map(state.items, (batch) => {
            return {
                item: batch.batchRef,
                val: batch.id
            }
        }), 'item');
    }
};

const mutations = {
    setBatches: (state, items) => {
        state.items = items;
    }
};

const actions = {
    loadBatches: async ({ commit, state }) => {
        let res = await Vue.axios.get('/batches', {
        });
        commit('setBatches', res.data)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};