<template>
    <div class="container grid-lg">
        <modals-container>
        </modals-container>
        <div class="columns">
            <div class="column col-6 col-sm-12">
                <div class="card">
                    <div class="content">
                        <div class="batch-header">
                            <div v-if="org">
                                <button class="button-tool" @click="back"><arrow-left-circle-icon class="icon"></arrow-left-circle-icon> Back</button>
                                <div>
                                    <div class="org-info">
                                        <p>
                                            Org: {{org.organization.displayName}}<br />
                                            ID: {{org.organization.id}}<br />
                                            Admin Contact: {{org.admin.username}}<br /><br />
                                            Address <br />
                                            Street: {{org.organization.properties.address.street_address}}<br />
                                            City: {{org.organization.properties.address.city}}<br />
                                            State: {{org.organization.properties.address.state}}<br />
                                            Country: {{org.organization.properties.address.country}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column col-6 col-sm-12">
                <div class="card">
                    <div class="content">
                        <div class="tools">
                            <h5>Admin Tools</h5>
                            <button class="button-tool" @click="reset"><download-icon class="icon"></download-icon> Send password reset email</button>
                            
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="content">
                    <div v-if="plan">
                        <h4>Plan</h4>
                        <div>
                            <p> {{ org.organization.displayName }} is currently subscribed to the {{ org.plan.tier | planTier }} plan.</p>
                        </div>
                        <p>
                            {{org.plan.balance}} Unique Codes Remaining
                        </p>
                        <p>
                            <button class="button-tool" @click="changeSubscription"><plus-circle-icon class="icon"></plus-circle-icon> Change Subscription</button>
                        </p>
                        <p>
                            <input class="form-input" v-model="newCodes">
                            <button class="button-tool" @click="changeCodes"><plus-circle-icon class="icon"></plus-circle-icon> Change Codes</button>
                        </p>
                    </div>
                    </div>
                </div>
            </div>
            <div class="column col-12">
                <div class="card">
                        <vue-good-table
                        mode="remote"
                        @on-page-change="onPageChange"
                        @on-per-page-change="onPerPageChange"
                        :pagination-options="{
                            enabled: true
                        }"
                        :sort-options="{
                            enabled: false,
                        }"
                        >
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import { ArrowLeftCircleIcon, ExternalLinkIcon, DownloadIcon, EditIcon, BarChart2Icon, BoxIcon, PlusCircleIcon } from 'vue-feather-icons';

import bugsnagClient from '../utils/bugsnag';

import EditBatchModal from './modals/EditBatchModal';
import ConfirmDialog from '../components/ConfirmDialog';
import ExportModal from './modals/ExportModal';
import DownloadQRModal from './modals/DownloadQRModal';
import AssetOperations from './modals/AssetOperations';

export default {
    name: 'AdminOrgs',
    components: {
        ArrowLeftCircleIcon, ExternalLinkIcon, DownloadIcon, BarChart2Icon, EditIcon, BoxIcon, PlusCircleIcon
    },
    mounted() {
        this.getOrg()
    },
    data() {
        return {
            newCodes: 0,
            id: this.$route.params.id,
            resetti: {
                email: ''
            },
            codes: {
                balance: 0
            },
            columns: [
                {
                    label: 'ID',
                    field: 'slugId',
                    formatFn: this.slugFormatter
                },
                {
                    label: 'Actions',
                    field: 'after'
                }
            ],
            invoiceColumns: [
                {
                    label: 'Amount',
                    field: 'total',
                    formatFn: this.amountFormatter,
                },
                {
                    label: 'Status',
                    field: 'status',
                    filterable: true,
                },
                {
                    label: 'Created On',
                    field: 'createdAt',
                    type: 'date',
                    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS\'Z\'',
                    dateOutputFormat: 'MMM dd, yyyy HH:mm',
                    filterable: true
                }
            ],
            loaded: false,
            org: null,
        };
    },
    filters: {
        planTier: function (tier) {
      if (!tier) return ''
      switch (tier) {
                case 'free':
                    return 'Community';
                case 'starter_m':
                    return 'Team (Monthly)'
                case 'pro_m':
                    return 'Pro (Monthly)'
                case 'starter_a':
                    return 'Team (Annual)'
                case 'pro_a':
                    return 'Pro (Annual)'
                default:
                    return '';
            }
    }
    },
    computed: {
        totalVal: function() {
            
            let sum = 0;
            for (let i = 0; i < this.invoices.length; i++) {
                sum += this.invoices[i].total
            }
            return sum;
        },
        ...mapGetters('account', {
            invoices: 'invoices',
            plan: 'plan'
        })},
    methods: {
        ...mapActions('account', [
          'loadPlan'
        ]),
        back() {
            this.$router.push({ name: 'admin' });
        },
        reset() {
            this.resetti.email = this.org.admin.username
            if (this.resetti.email == '') {
                this.error = "Missing required email address";
                return;
            }
            axios.post(`${process.env.VUE_APP_PAIZA_ENDPOINT}/auth/requestreset`, this.resetti)
            .then((res) => {
                this.resetsent = true;
            })
            .catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to reset password.',
                    text: 'Please try again later.'
                })
            })
            .finally(() => {
                alert('sent to ' + this.org.admin.username)
            })
        },
        getOrg() {
            this.$http.get(`admin/organizations/${this.id}`)
            .then((orgRes) => {
                this.org = orgRes.data;
            })
            .catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to retrieve organisation.',
                    text: 'Please try again later.'
                })
            })
            .finally(() => {
                
            })
        },
        changeSubscription() {

        },
        slugFormatter (slug) {
            return slug;
        },
        slugUrl: function(slug) {
            return `${Vue.config.sulde}/p/${slug}`
        },
        
        updateParams(newProps) {
            this.atomParams = Object.assign({}, this.atomParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            
        },
        onPerPageChange(params) {
            this.updateParams({limit: params.currentPerPage});
            
        },
        changeCodes(){
            let newCodes = parseInt(this.newCodes)
            if (!isNaN(newCodes))
            {
                if(confirm("changing user's number of codes to " + newCodes))
                {
                    this.codes.balance = newCodes
                    this.$http.put(`admin/organizations/${this.id}/balance`, this.codes)
            .catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to retrieve organisation.',
                    text: 'Please try again later.'
                })
            })
            .finally(() => {
                this.getOrg()
            })

                }
            }
            else alert("NaN error, sorry")
        }
        
    }
}
</script>
<style lang="scss" scoped>
.batch-header {
    h2 {
      display: inline-block;
      margin-left: 16px;
    }
    .close {
      float:right;
      margin: 16px;
    }
    margin-bottom: 4px;
  }
  .batch-info {
      margin-top: 16px;
      float: left;
      margin-bottom: 16px;
  }
  .batch-qr {
      float: right;
  }
  .button-tool {
      text-align: left;
      margin-bottom: 10px;
  }
  .tools {
      display:flex;
      flex-direction: column;
      justify-content: space-between;
  }
  .row-actions {
      display:flex;
      flex-direction: row;
      justify-content: left;
  }
</style>
