<template>
  <div class="container grid-lg">
    <modals-container>
    </modals-container>
    <div class="columns">
      <div class="column col-12">
        <div class="card">
          <div v-if="!loaded" class="loading loading-lg"></div>
          <div v-show="loaded" class="content">
            <div class="columns">
              <div class="col-12 button-top">
                <button class="button-tool" @click="newTemplate">
                  <plus-circle-icon class="icon"></plus-circle-icon> New Template
                </button>
              </div>
            </div>
          </div>
          <vue-good-table 
            mode="remote" 
            @on-page-change="onPageChange" 
            @on-per-page-change="onPerPageChange" 
            v-show="loaded" 
            :columns="columns" 
            :rows="templates" 
            :totalRows="count" 
            :search-options="{
              enabled: true,
              placeholder: 'Search Templates'
            }" 
            :pagination-options="{
              enabled: true
            }" 
            @on-row-click="onRowClick" 
            @on-search="onSearch" 
            :sort-options="{
              enabled: true,
              initialSortBy: {field: 'updatedAt', type: 'desc'}
            }" 
            :isLoading.sync="isLoading"
            @on-sort-change="onSortChange">
            <template slot="table-row" slot-scope="props">
              {{props.formattedRow[props.column.field]}}
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
const _ = require('lodash');
import { PlusCircleIcon } from 'vue-feather-icons';

import bugsnagClient from '../utils/bugsnag';

import NewTemplateModel from './modals/NewTemplateModal';
import moment from 'moment-timezone';
export default {
  name: 'Templates',
  components: {
    PlusCircleIcon
  },
  data() {
    return {
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterable: true
        },
        {
          label: 'SKU',
          field: 'sku',
          filterable: true
        },
        {
          label: 'Created On',
          field: 'createdAt',
          type: 'date',
          formatFn: this.formatFn,
        },
        {
          label: 'Updated At',
          field: 'updatedAt',
          type: 'date',
          formatFn: this.formatFn,
        }
      ],
      model: {},
      schema: null,
      loaded: false,
      isLoading: false,
      params: {
        page: 1,
        limit: 10,
        search: '',
        sort: {
          field: '', 
          type: ''
        }
      },
      templates: [],
      count: 0
    };
  },
  methods: {
    formatFn: function(value) {
      var d = moment.tz(value,"UTC")
      return d.tz(moment.tz.guess()).format("MMM Do, YYYY HH:mm");
    },
    async loadTemplates() {
      this.isLoading = true;
      let res = await Vue.axios.get('/templates', {
        params: this.params
      });
      this.templates = res.data.templates;
      this.count = res.data.count;
      this.isLoading = false;
    },
    onRowClick(params) {
      this.$router.push({ name: 'template', params: { id: params.row.id } });
    },
    newTemplate(){
      this.$modal.show(NewTemplateModel, {
        finishedCallback: (id) => {
          this.$router.push({name: 'template', params: {id: id}})
        }
      }, {
        adaptive: true,
        minHeight: 600,
        scrollable: false
      });
    },
    updateParams(newProps) {
      this.params = Object.assign({}, this.params, newProps);
    },
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadTemplates();
    },
    onPerPageChange(params) {
      this.updateParams({limit: params.currentPerPage});
      this.loadTemplates();
    },
    onSearch: _.debounce(function(params) {
      if (params.searchTerm.length == 0) {
        this.updateParams({
          page: params.page = 1,
          search: params.searchTerm = ''
        });
        this.loadTemplates();
        return;
      } else if (params.searchTerm.length < 3) {
        return;
      }
      this.updateParams({search: params.searchTerm});
      this.loadTemplates();
    }, 200),
    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.loadTemplates();
    },
  },       
  created() {
    this.loadTemplates().then(() => {
      this.loaded = true;
    }).catch((err) => {
      this.isLoading = false;
      bugsnagClient.notify(err);
      this.$notify({
        type: 'error',
        title: 'Failed to load templates.',
        text: 'Please try again later.'
      })
    })
  }
}
</script>
