<template>
    <div style="vertical-align: -6px" class="pop">
        <v-popover>
            <help-circle-icon class="tooltip-target"></help-circle-icon>
            <template slot="popover">
              <div class="popover-content">
                  <div class='header'><h3>{{ titleGood }}</h3></div>
                  <div><p v-html="textGood"></p></div>
              </div>
            </template>
        </v-popover>
        </div>
</template>

<script>

import { HelpCircleIcon } from 'vue-feather-icons';


export default {

    name: "TipModal",
    props: {
        title: String,
        text: String,
    },

    components: { HelpCircleIcon },
    
    computed: {
        titleGood: function () {
            return this.title.replace("~~","{{").replace("^^","}}");
        },
        textGood: function () {
            return this.text.replace("~~","{{").replace("^^","}}");
        }
    }

}
    
</script>

<style lang="scss">
.pop {
  display: inline-block;
}

.tooltip-target {
  cursor: pointer;
}
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 8px;
    padding: 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: transparent;
      color: black;
      border-radius: 0px;
    }

    .popover-content {
      width: 220px;
      padding: 12px;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 5px 30px rgba(black, .2);

      h3 {
        font-size: 0.9rem;
      }

      p {
        font-size: 0.7rem;
      }
    }

    .popover-color {
      background: transparent;
      padding: 0px;
    }

    .popover-arrow {
      border-color: #000;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
.tooltip::after {
  display: none;
}
</style>