<template>
    <div class="container grid-lg">
        <modals-container>
        </modals-container>
        <div class="columns">
            <div class="column col-6 col-sm-12">
                <div class="card">
                    <div class="content">
                        <div class="batch-header">
                            <div v-if="batch">
                                <button class="button-tool" @click="back"><arrow-left-circle-icon class="icon"></arrow-left-circle-icon> Batches</button>
                                <div>
                                    <div class="batch-info">
                                        <p>
                                            Batch: {{batch.batchRef}}<br />
                                            SKU: {{batch.sku}}<br />
                                            Total Size: {{batch.atomCount}}
                                        </p>
                                        <o-button @click.native="openAnalytics">View Analytics for Batch</o-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column col-6 col-sm-12">
                <div class="card">
                    <div class="content">
                        <div v-if="!loaded" class="loading loading-lg"></div>
                        <div v-else class="tools">
                            <h5>Batch Tools</h5>
                            <button class="button-tool" @click="downloadQR"><download-icon class="icon"></download-icon> Download/View Batch QR</button>
                            <button class="button-tool" @click="exportCodes"><download-icon class="icon"></download-icon> Export Codes</button>
                            <button class="button-tool" @click="editBatch"><edit-icon class="icon"></edit-icon> Edit Batch</button>
                            <button class="button-tool" @click="archiveBatch"><edit-icon class="icon"></edit-icon> Archive Batch</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column col-12">
                <div class="card">
                    <div v-if="!loaded" class="loading loading-lg"></div>
                    <div v-else>
                        <vue-good-table
                        mode="remote"
                        @on-page-change="onPageChange"
                        @on-per-page-change="onPerPageChange"
                        :pagination-options="{
                            enabled: true
                        }"
                        :sort-options="{
                            enabled: false,
                        }"
                        :totalRows="batch.atomCount"
                        :columns="columns"
                        :rows="atoms"
                        >
                        <template slot="table-row" slot-scope="props">
                            <div class="row-actions" v-if="props.column.field == 'after'">
                                <div v-tooltip="'View Analytics'">
                                    <o-button buttonStyle="icon" @click.native="atomAnalytics(props.row)">
                                        <bar-chart-2-icon></bar-chart-2-icon>
                                    </o-button>
                                </div>
                                <div v-tooltip="'Download QR'">
                                    <o-button buttonStyle="icon" @click.native="atomQR(props.row)">
                                        <download-icon></download-icon>
                                    </o-button>
                                </div>
                                <div v-tooltip="'View Asset'">
                                    <o-button buttonStyle="icon" @click.native="openAtom(props.row)">
                                        <external-link-icon></external-link-icon>
                                    </o-button>
                                </div>
                                <div v-tooltip="'View Operations'">
                                    <o-button buttonStyle="icon" @click.native="atomOperations(props.row)">
                                          <box-icon></box-icon>
                                    </o-button>
                                </div>
                            </div>
                            <div v-else>
                            {{props.formattedRow[props.column.field]}}
                            </div>
                        </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { ArrowLeftCircleIcon, ExternalLinkIcon, DownloadIcon, EditIcon, BarChart2Icon, BoxIcon } from 'vue-feather-icons';

import bugsnagClient from '../utils/bugsnag';

import EditBatchModal from './modals/EditBatchModal';
import ConfirmDialog from '../components/ConfirmDialog';
import ExportModal from './modals/ExportModal';
import DownloadQRModal from './modals/DownloadQRModal';
import AssetOperations from './modals/AssetOperations';

export default {
    name: 'Batch',
    components: {
        ArrowLeftCircleIcon, ExternalLinkIcon, DownloadIcon, BarChart2Icon, EditIcon, BoxIcon
    },
    mounted() {
        this.getBatch();
    },
    data() {
        return {
            id: this.$route.params.id,
            columns: [
                {
                    label: 'ID',
                    field: 'slugId',
                    formatFn: this.slugFormatter
                },
                {
                    label: 'Actions',
                    field: 'after'
                }
            ],
            loaded: false,
            batch: null,
            atoms: [],
            atomParams: {
                page: 1,
                limit: 10
            }
        };
    },
    methods: {
        back() {
            this.$router.push({ name: 'batches' });
        },
        getBatch () {
            this.loaded = false;
            this.$http.get(`/batches/${this.id}`)
            .then((batchRes) => {
                this.batch = batchRes.data;
            })
            .then(this.loadAtoms())
            .catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to retrieve batch.',
                    text: 'Please try again later.'
                })
            })
            .finally(() => {
                this.loaded = true;
            })
        },
        slugFormatter (slug) {
            return slug;
        },
        slugUrl: function(slug) {
            return `${Vue.config.sulde}/p/${slug}`
        },
        loadAtoms () {
            return this.$http.get(`/batches/${this.id}/atoms`, {
                params: this.atomParams
            }).then((atomsRes) => {
                this.atoms = atomsRes.data;
                if (this.$route.query.show == true) {
                    this.downloadQR();
                }
            }).catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to retrieve smart codes for batch.',
                    text: 'Please try again later.'
                })
            })
        },
        updateParams(newProps) {
            this.atomParams = Object.assign({}, this.atomParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.loadAtoms();
        },
        onPerPageChange(params) {
            this.updateParams({limit: params.currentPerPage});
            this.loadAtoms();
        },
        atomAnalytics(row){
            this.$router.push({ name: 'analytics', query: { atomid: row.slugId } });
        },
        atomQR(row){
            this.$modal.show(DownloadQRModal, {
                atom: row,
                link: row.link,
                finishedCallback: () => {
                }
            }, {
                adaptive: true,
                minHeight: 350,
                scrollable: false
            })
        },
        atomOperations(row){
            this.$modal.show(AssetOperations, {
                id: row.id
            }, {
                adaptive: true,
                minHeight: 450,
                height: "auto",
                scrollable: true
            });
        },
        openAtom(row){
            window.open(`${Vue.config.sulde}/p/${row.slugId}`);
        },
        exportCodes(){
            this.$modal.show(ExportModal, {
                id: this.batch.id,
                exportType: 'batch',
                finishedCallback: () => {
                    this.getBatch(this.batch.id);
                }
            }, {
                adaptive: true,
                minHeight: 200,
                scrollable: false
            })
        },
        editBatch(){
            this.$modal.show(EditBatchModal, {
                id: this.batch.id,
                currentBatch: this.batch,
                finishedCallback: () => {
                    this.getBatch(this.batch.id);
                }
            }, {
                adaptive: true,
                minHeight: 600,
                scrollable: true,
                height: "auto"
            })
        },
        archiveBatch(){
            this.$modal.show(ConfirmDialog, {
                finishedCallback: (confirmed) => {
                    if (confirmed) {
                        this.$http.delete(`/batches/${this.id}`)
                        .then(() => {
                            this.$router.push({ name: 'batches' })
                        });
                    }
                },
                message: 'Are you sure you want to archive this batch?',
                primaryButtonTitle: 'Archive Batch'
            }, {
                height: 140,
                width: 300,
                scrollable: false
            })
        },
        openAnalytics() {
            this.$router.push({ name: 'analytics', query: { batchid: this.id } });
        },
        downloadQR() {
            this.$modal.show(DownloadQRModal, {
                atom: this.atoms[0],
                link: this.atoms[0].link,
                finishedCallback: () => {
                }
            }, {
                adaptive: true,
                minHeight: 350,
                scrollable: false
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.batch-header {
    h2 {
      display: inline-block;
      margin-left: 16px;
    }
    .close {
      float:right;
      margin: 16px;
    }
    margin-bottom: 4px;
  }
  .batch-info {
      margin-top: 16px;
      float: left;
      margin-bottom: 16px;
  }
  .batch-qr {
      float: right;
  }
  .button-tool {
      text-align: left;
      margin-bottom: 10px;
  }
  .tools {
      display:flex;
      flex-direction: column;
      justify-content: space-between;
  }
  .row-actions {
      display:flex;
      flex-direction: row;
      justify-content: left;
  }
</style>
