<template>
<div>
  <div class="file-search-modal">
    <div class="header">
      <h3>Search/Upload File</h3>
    </div>
    <button style="margin-bottom: 16px" class="button-tool" id="upload-files"><plus-circle-icon class="icon"></plus-circle-icon> Upload Files</button>
        
    <form>
      <div class="form-group">
        <vue-autosuggest
          :suggestions="files"
          @input="onInputChange"
          :input-props="{id:'autosuggest__input', placeholder:'Search by file name'}"
          @selected="fileSelected"
          :get-suggestion-value="getSuggestionValue">
          <template slot-scope="{suggestion}">
            <span class="my-suggestion-item">{{suggestion.item.meta.name}} ({{suggestion.item.fileType}})</span>
          </template>
        </vue-autosuggest>
      </div>
    </form>
    <div class="file-footer">
      <button class="button-tool" @click="finishFileSelection" v-bind:disabled="!this.file">Select file <arrow-right-circle-icon class="icon"></arrow-right-circle-icon></button>
    </div>
  </div>
  <div class="file-helper">
    <div class="container">
      <div class="columns">
        <div class="column col-2">
          <info-icon></info-icon>
        </div>
        <div class="column col-10">
          <p>
            Files must be uploaded in <router-link target="_blank" :to="{ name: 'files'}">Files</router-link> before they will appear in this selector.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
const _ = require('lodash');
import Vue from 'vue';
import { VueAutosuggest } from 'vue-autosuggest';
import { ArrowRightCircleIcon, InfoIcon, PlusCircleIcon, FileTextIcon } from 'vue-feather-icons';
import OButton from './OButton.vue';
import FileModal from '../views/modals/FileModal';
import moment from 'moment-timezone';
import bugsnagClient from '../utils/bugsnag';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import Dashboard from '@uppy/dashboard';


export default {
  name: 'FileSearchModal',
  props: ['acceptedTypes', 'finishedCallback'],
  mounted() {
    const uppy = Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: 35000000,
        minNumberOfFiles: 1,
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/jpeg', 'video/mp4', 'image/png', 'application/pdf', 'application/zip', '.woff', '.woff2']
      },
      onBeforeUpload: (files) => {
        // We’ll be careful to return a new object, not mutating the original `files`
        const updatedFiles = {}
        Object.keys(files).forEach(fileID => {
          let newMeta = files[fileID].meta;
          newMeta.meta = JSON.stringify({
            name: newMeta.name,
            tags: newMeta.tags ? newMeta.tags.split(',') : []
          });
          updatedFiles[fileID] = {
            ...files[fileID],
            meta: newMeta
          }
        })
        return updatedFiles
      }
    })
    .use(Dashboard, {
      showProgressDetails: true,
      note: 'Images (jpg, png, pdf) and video (mp4) only, up to 35 MB',
      height: 470,
      metaFields: [
        { id: 'name', name: 'Name', placeholder: 'File name' },
        { id: 'tags', name: 'Tags', placeholder: 'File tags (comma separated)' }
      ],
      browserBackButtonClose: true,
      proudlyDisplayPoweredByUppy: false,
      trigger: '#upload-files'
    })
    .use(XHRUpload, { 
      endpoint: `${process.env.VUE_APP_PAIZA_ENDPOINT}/files`,
      fieldName: 'file',
      headers: {
        'authorization': `Bearer ${this.$auth.token()}`
      },
      metaFields: ['meta']
    });

    uppy.on('file-added', (file) => {
      uppy.setFileMeta(file.id, {
        meta: JSON.stringify({
          name: file.meta.name
        })
      })
    })

    uppy.on('complete', result => {
      this.loadFiles()
      console.log('successful files:', result.successful)
      console.log('failed files:', result.failed)
      this.file = result.successful[0].response.body
      this.finishFileSelection();
    });
  },
  data() {
    return {
      file: null,
      files: []
    }
  },
  components: {
    ArrowRightCircleIcon, InfoIcon,
    VueAutosuggest,
    OButton,
    PlusCircleIcon, FileTextIcon
  },
  methods: {
    async onInputChange(text, oldText) {
      if (text == null) {
        return;
      }
      if (text.length < 3){
        return;
      }
      if (text == oldText){
        return;
      }

      let res = await Vue.axios.get('/files', {
        params: { 
          page: 1,
          limit: 50,
          search: text
        }
      });

      this.files = [{ data: res.data.files }];
    },
    fileSelected(suggestion) {
      this.file = suggestion.item;
    },
    finishFileSelection() {
      console.log(this.file);
      if (this.finishedCallback) this.finishedCallback(this.file.id);
      this.$emit('close')
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.meta.name;
    },
    formatFn: function(value) {
      var d = moment.tz(value,"UTC")
      return d.tz(moment.tz.guess()).format("MMM Do, YYYY HH:mm");
    },
    async loadFiles() {
      this.isLoading = true;
      let res = await Vue.axios.get('/files', {
        params: this.params
      });
      this.files = res.data.files;
      this.count = res.data.count;
      this.isLoading = false;
    },
    newFile() {
      this.$modal.show(FileModal, {
        finishedCallback: () => {
          this.loadFiles();
        }
      }, {
        adaptive: true,
        minHeight: 300,
        height: 'auto'
      })
    },
    editFile(params) {
      this.$modal.show(FileModal, {
        file: params.row,
        finishedCallback: () => {
          this.loadFiles();
        }
      }, {
        adaptive: true,
        minHeight: 300,
        scrollable: true,
        height: 'auto'
      })
    },
    fileTypeFormatter (fileType) {
      switch (fileType) {
        case 'image/jpeg':
          return 'Image (JPEG)';
        case 'application/pdf':
          return 'Document (PDF)';
        case 'image/png':
          return 'Image (PNG)';
        case 'video/mp4':
          return 'Video (MP4)';
        case 'model/usd':
          return 'Apple ARKit (USDZ)';
        case 'application/zip':
          return 'Archive (ZIP)';
        default:
          return 'Unknown';
      }
    },
    identityFormatter (identityId) {
      let identity = this.identities[identityId]
      return identity.username;
    },
    tagFormatter (tags) {
      return tags.join(', ');
    },
    updateParams(newProps) {
      this.params = Object.assign({}, this.params, newProps);
    },
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadFiles()
    },
    onPerPageChange(params) {
      this.updateParams({limit: params.currentPerPage});
      this.loadFiles()
    },
    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.loadFiles();
    },
    onSearch: _.debounce(function(params) {
      if (params.searchTerm.length == 0){
        this.updateParams({
          page: params.page = 1,
          search: params.searchTerm = ''
        })
        this.loadFiles()
      } else if (params.searchTerm.length < 3){
        return;
      }
      this.updateParams({search: params.searchTerm});
      this.loadFiles();
    }, 200),
  },
  created() {
    this.loadFiles().then(() => {
      this.loaded = true;
    }).catch((err) => {
      this.isLoading = false;
      bugsnagClient.notify(err);
      this.$notify({
        type: 'error',
        title: 'Failed to retrieve files.',
        text: 'Please try again later.'
      })
    })
  }
}
</script>
<style lang="scss" scoped>
  .file-search-modal {
    padding: 16px;
  }
  .file-footer {
    border-top: 1px solid #dddddd;
    float:right;
    margin-right: 0px;
    padding-top: 24px;

    .button-tool {
      float:right;
      margin: 0px;
      z-index: 9999999;
    }
    .footer-text {
      display: inline-block;
      margin: 16px;
    }
    margin-bottom: 4px;
  }
  .file-helper {
    background-color: #efefef;
    padding: 16px 16px 0px 8px;
    bottom: 0;
    position: absolute;
    width: 100%
  }
</style>
