var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container grid-lg"},[_c('modals-container'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column col-12"},[_c('div',{staticClass:"card"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded),expression:"loaded"}],staticClass:"content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"col-12 button-top"},[_c('button',{staticClass:"button-tool",attrs:{"id":"upload-files"}},[_c('plus-circle-icon',{staticClass:"icon"}),_vm._v(" Upload Files")],1)])])]),(!_vm.loaded)?_c('div',{staticClass:"loading loading-lg"}):_c('div',[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.files,"totalRows":_vm.count,"search-options":{
            enabled: true,
            placeholder: 'Search Files'
          },"pagination-options":{
            enabled: true
          },"sort-options":{
            enabled: true,
            initialSortBy: {field: 'updatedAt', type: 'desc'}
          },"isLoading":_vm.isLoading},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-row-click":_vm.editFile,"on-search":_vm.onSearch,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'url')?_c('div',{staticClass:"previewFile"},[(props.row['fileType'] == 'image/jpeg')?_c('img',{staticClass:"previewImage",attrs:{"src":props.formattedRow[props.column.field]}}):_c('file-text-icon')],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }