<template>
<div>
  <div v-if="editing" class="loading-cover">
    <div>
    <div class="loading loading-lg"></div>
    <span>Editing Batch...</span>
    </div>
  </div>
  <div class="batch-modal">
      <div class="header">
        <h3>Edit Batch</h3>
        <p>This will overwrite the Unique Codes within this Batch with the selected Template data.</p>
      </div>
      <form>
        <div class="form-group">
          <label class="form-label" for="batchTemplate">Select a Template</label>
          <vue-autosuggest
            v-model="suggestModel"
            :suggestions="templates"
            @input="onInputChange"
            :input-props="{id:'autosuggest__input', placeholder:'Select a template'}"
            @selected="templateSelected">
            <template slot-scope="{suggestion}">
              <span class="my-suggestion-item">{{suggestion.item.name}}</span>
            </template>
          </vue-autosuggest>
        </div>
        <div class="form-group" v-if="selectedTemplate">
          <div v-for="(attribute, attributeKey) in selectedTemplate.attributes" :key="attributeKey">
            <label class="form-label" :for="attributeKey+'_attribute'">{{attributeKey}}</label>
            <input class="form-input" v-if="attribute.attributeType == 'text'" type="text" :id="attributeKey+'_attribute'" v-model="batch.attributes[attributeKey]">
            <file-selector v-if="attribute.attributeType == 'file'" :maxFiles=1 v-bind:files="batch.attributes[attributeKey]" v-on:update="(file) => {batch.attributes[attributeKey] = file}"></file-selector>
          </div>
        </div>
      </form>

      <div class="batch-footer">
        <button class="button-tool" @click="editBatch" v-bind:disabled="!completed()">Edit and Apply to Batch <arrow-right-circle-icon class="icon"></arrow-right-circle-icon></button>
      </div>
  </div>
  <div class="batch-helper">
      <div class="container">
          <div class="columns">
              <div class="column col-2">
                   <info-icon></info-icon>
              </div>
              <div class="column col-10">
                   <p>A batch is a grouping of Unique Codes. 
                    </p>
              </div>
          </div>
      </div>
  </div>
  </div>
</template>

<script>
const _ = require('lodash');
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { VueAutosuggest } from 'vue-autosuggest';
import { ArrowRightCircleIcon, InfoIcon } from 'vue-feather-icons';

import bugsnagClient from '../../utils/bugsnag';

import CreditsModal from './CreditsModal';
import FileSelector from '../../components/FileSelector';

export default {
  name: 'EditBatchModal',
  props: ['id', 'currentBatch', 'finishedCallback'],
  data() {
    return {
      batch: {
        batchRef: this.currentBatch.batchRef,
        templateId: this.currentBatch.templateId,
        attributes: this.currentBatch.attributeValues ? this.currentBatch.attributeValues : {},
      },
      suggestModel: null,
      templates: [],
      editing: false,
      selectedTemplate: null,
    }
  },
  components: {
    ArrowRightCircleIcon, InfoIcon,
    VueAutosuggest,
    FileSelector
  },
  mounted() {
    this.getSelectedTemplate()
  },
  computed: {
    ...mapGetters('account', {
      plan: 'plan'
    })
  },
  methods: {
    ...mapActions('account', [
      'loadPlan'
    ]),
    getSelectedTemplate () {   
      if (this.currentBatch.templateId != null){
        this.$http.get(`/templates/${this.currentBatch.templateId}`).then((res) => {
          const t = res.data;
          this.suggestModel = t.name;
          this.selectedTemplate = t;
        }).catch((err) => {
          bugsnagClient.notify(err);
          this.$notify({
            type: 'error',
            title: 'Failed to retrieve template.',
            text: 'Please try again later.'
          })
        });
      }
    },
    async onInputChange(text, oldText) {
      if (text == null) {
        return;
      }
      if (text.length < 3){
        return;
      }
      if (text == oldText){
        return;
      }

      let res = await Vue.axios.get('/templates', {
        params: { 
          page: 1,
          limit: 50,
          search: text
        }
      });
      this.templates = [{ data: res.data.templates }];
    },
    templateSelected(template) {
      this.selectedTemplate = template.item;
      this.batch.templateId = template.item.id;
      this.batch.attributes = _.mapValues(template.item.attributes, (attribute) => {
        if (template.item.id === this.currentBatch.templateId){
          let val = this.currentBatch.attributeValues[attribute.name];
          if (val !== null){
            return val;
          }
        }
        return attribute.defaultVal;
      });
    },
    editBatch() {
      this.editing = true;
      this.$http.put(`/batches/${this.id}`, {
        templateId: this.batch.templateId,
        attributes: this.batch.attributes
      }).then((res) => {
        this.editing = false;
        if (this.finishedCallback) this.finishedCallback();
        this.$emit('close')
      }).catch((err) => {
        this.editing = false;
        bugsnagClient.notify(err);
        this.$notify({
          type: 'error',
          title: 'Failed to edit batch.',
          text: 'Please try again later.'
        })
        return;
      });
    },
    completed: function(){
      if (!this.batch && !this.plan) return false;
      if (this.batch.templateId != null){
        return true
      }
      return false;
    }
  }
}
</script>
<style lang="scss" scoped>
  .batch-modal {
    padding: 16px;
    padding-bottom: 148px;
  }
  .batch-footer {
    float:right;
    margin-right: 0px;
    padding-top: 24px;

    .button-tool {
      float:right;
      margin: 0px;
      z-index: 9999999;
    }
    .footer-text {
      display: inline-block;
      margin: 16px;
    }
    margin-bottom: 4px;
  }
  .batch-helper {
    background-color: #efefef;
    padding: 16px 16px 0px 8px;
    bottom: 0;
    position: absolute;
    width: 100%
  }
</style>
