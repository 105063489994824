<template>
<div>
  <div class="template-modal">
    <div class="header">
      <h3>New Template</h3>
    </div>
    <form>
      <ValidationProvider name="template-name" rules="required|min:3" v-slot="{ errors }">
        <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
          <label class="form-label" for="name">Template Name <tip-modal title="Template Name" text="The template name is used to help you identify a template."></tip-modal></label>
          <input class="form-input" type="text" id="name" v-model="name" placeholder="New Product Template">
          <p class="form-input-hint">{{ errors[0] }}</p>
        </div>
      </ValidationProvider>

      <ValidationProvider name="template-name" rules="required|min:3" v-slot="{ errors }">
        <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
          <label class="form-label" for="sku">Template SKU <tip-modal title="Template SKU" text="The template SKU is an internal identifier to help you identify a template for a particular product."></tip-modal></label>
          <input class="form-input" type="text" id="sku" v-model="sku" placeholder="NEWPRODUCT001">
          <p class="form-input-hint">{{ errors[0] }}</p>
        </div>
      </ValidationProvider>

      <div class="form-group">
        <label class="form-label" for="copyTemplate">Copy from Existing Template</label>
        <vue-autosuggest
          :suggestions="templates"
          @input="onInputChange"
          :input-props="{id:'autosuggest__input', placeholder:'Select a template'}"
          @selected="templateSelected">
          <template slot-scope="{suggestion}">
            <span class="my-suggestion-item">{{suggestion.item.name}}</span>
          </template>
        </vue-autosuggest>
      </div>
    </form>
    <div class="template-footer" v-if="!loading">
      <button class="button-tool" @click="newTemplate" v-bind:disabled="this.sku == '' || this.name == '' || this.sku.length < 3 || this.name.length < 3">Create New Template <arrow-right-circle-icon class="icon"></arrow-right-circle-icon></button>
    </div>
    <div class="template-footer" v-else>
      <div class="loading loading-lg"></div>
    </div>
  </div>
  <div class="template-helper">
    <div class="container">
      <div class="columns">
        <div class="column col-2">
          <info-icon></info-icon>
        </div>
        <div class="column col-10">
          <p>A template is used to assign properties to a one or more Smart Codes.
          </p>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { VueAutosuggest } from 'vue-autosuggest';
import { ArrowRightCircleIcon, InfoIcon } from 'vue-feather-icons';
import { ValidationProvider, extend } from 'vee-validate';
import { required, min } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required.'
});

extend('min', {
  ...min,
  message: 'This field must be longer than 3 characters.'
});

import bugsnagClient from '../../utils/bugsnag';
import TipModal from '../../components/Tip';

export default {
  name: 'NewTemplateModal',
  props: ['finishedCallback'],
  data() {
    return {
      name: '',
      sku: '',
      loading: false,
      properties: {
        translations: [
          {
            language: 'en',
            name: 'New Product Title',
            details: []
          }
        ]
      },
      attributes: {},
      templates: []
    }
  },
  components: {
    ArrowRightCircleIcon, InfoIcon,
    VueAutosuggest, TipModal, ValidationProvider
  },
  methods: {
    async onInputChange(text, oldText) {
      if (text == null) {
        return;
      }
      if (text.length < 3){
        return;
      }
      if (text == oldText){
        return;
      }

      let res = await Vue.axios.get('/templates', {
        params: { 
          page: 1,
          limit: 50,
          search: text
        }
      });
      this.templates = [{ data: res.data.templates }];
    },
    templateSelected(template) {
      this.properties = template.item.properties;
      this.attributes = template.item.attributes;
    },
    newTemplate() {
      this.loading = true;
      this.$http.get(`/nuclei`).then((nucleiData) => {
        let nucleusId = nucleiData.data[0].id;
        this.$http.post(`/templates`, {
          name: this.name,
          nucleusId: nucleusId,
          properties: this.properties,
          attributes: this.attributes,
          sku: this.sku
        }).catch((err) => {
        this.loading = false;
        bugsnagClient.notify(err);
        this.$emit('close');
        this.$notify({
          type: 'error',
          title: 'Failed to create template.',
          text: 'Please try again later.'
        });
      }).then((res) => {
          this.loading = false;
          if (this.finishedCallback) {
            this.finishedCallback(res.data.id)
          }
          this.$emit('close');
        })
      }).catch((err) => {
        this.loading = false;
        bugsnagClient.notify(err);
        this.$emit('close');
        this.$notify({
          type: 'error',
          title: 'Failed to create template.',
          text: 'Please try again later.'
        });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .template-modal {
    padding: 16px;
  }
  .template-footer {
    border-top: 1px solid #dddddd;
    float:right;
    margin-right: 0px;
    padding-top: 24px;

    .loading {
      margin-right: 24px;
    }

    .button-tool {
      float:right;
      margin: 0px;
      z-index: 9999999;
    }
    .footer-text {
      display: inline-block;
      margin: 16px;
    }
    margin-bottom: 4px;
  }
  .template-helper {
    background-color: #efefef;
    padding: 16px 16px 0px 8px;
    bottom: 0;
    position: absolute;
    width: 100%
  }
</style>
