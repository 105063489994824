import Vue from 'vue';
const _ = require('lodash');

const state = {
    items: []
};

const getters = {
    templates: (state) => {
        return state.items;
    }
};

const mutations = {
    setTemplates: (state, items) => {
        state.items = items;
    }
};

const actions = {
    loadTemplates: async ({ commit, state }) => {
        let res = await Vue.axios.get('/templates', {
        });
        commit('setTemplates', res.data)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};