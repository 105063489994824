<template>
    <div>
        <div class="container">
            <div id="login-modal">
                <div class="column">
                    <div class="card">
                        <div class="content">
                            <h2>Login</h2>
                            <form v-on:submit.prevent="login()">
                                <div v-show="error" class="toast toast-error">
                                    Invalid username or password.
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="input-username">Email Address</label>
                                    <input class="form-input" type="text" id="input-username" v-model="data.body.username">
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="input-password">Password</label>
                                    <input class="form-input" id="input-password"  v-model="data.body.password" type="password">
                                </div>
                                <div v-if="isLoading" class="loading loading-lg"></div>
                                <div class="flex-container" style="display: flex; justify-content: space-between;" v-else>
                                    <button type="submit" class="btn btn-primary">Log in</button>
                                    <a style="margin-top: 8%; cursor: pointer;" @click='() => this.$router.push({name: "forgot"})'>Forgot Password?</a>
                                </div>
                            </form>
                        </div>
                    </div>
                    <a style="cursor: pointer;" @click="() => this.$router.push({name: 'signup'})">Sign Up</a>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            context: 'login',
            data: {
                body: {
                    username: '',
                    password: ''
                }
            },
            isLoading: false,
            error: null
        };
    },
    mounted() {
        if (this.$auth.check()){
            console.log("authentication successful")
            this.$router.push({name: 'home'})
        }
    },
    methods: {
        login() {
            this.isLoading = true;
            this.$auth.login({
                body: this.data.body,
                data: this.data.body,
                redirect: '/dashboard/home',
                fetchUser: true
            })
            .then(() => {
                this.isLoading = false;
                console.log('success ' + this.context);
            }, (res) => {
                this.isLoading = false;
                console.log(res)
                console.log('error ' + this.context);
                this.error = res;
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    #login-modal {
        width: 360px;
        margin: 100px auto;
    }
    .btn {
       margin-top:1rem;
    }
    .flex-container {

    }
</style>
