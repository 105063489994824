<template>
  <div class="container grid-lg">
    <modals-container>
    </modals-container>
    <div class="columns">
      <div class="column col-12">
        <div class="card">
          <div v-show="loaded" class="content">
            <div class="columns">
              <div class="col-12 button-top">
                <button class="button-tool" id="upload-files"><plus-circle-icon class="icon"></plus-circle-icon> Upload Files</button>
              </div>
            </div>
          </div>
          <div v-if="!loaded" class="loading loading-lg"></div>
          <div v-else>
            <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
            :columns="columns"
            :rows="files"
            :totalRows="count"
            :search-options="{
              enabled: true,
              placeholder: 'Search Files'
            }"
            :pagination-options="{
              enabled: true
            }"
            :sort-options="{
              enabled: true,
              initialSortBy: {field: 'updatedAt', type: 'desc'}
            }"
            :isLoading.sync="isLoading"
            @on-row-click="editFile"
            @on-search="onSearch"
            @on-sort-change="onSortChange">
            <template slot="table-row" slot-scope="props">
              <div class="previewFile" v-if="props.column.field == 'url'">
                <img class="previewImage" :src="props.formattedRow[props.column.field]" v-if="props.row['fileType'] == 'image/jpeg'">
                <file-text-icon v-else></file-text-icon>
              </div>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
const _ = require('lodash');
import { PlusCircleIcon, FileTextIcon } from 'vue-feather-icons';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import Dashboard from '@uppy/dashboard';
import bugsnagClient from '../utils/bugsnag';
import FileModal from './modals/FileModal';
import moment from 'moment-timezone';

export default {
  name: 'Files',
  components: {
    PlusCircleIcon, FileTextIcon
  },
  mounted() {
    const uppy = Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: 35000000,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/jpeg', 'video/mp4', 'image/png', 'application/pdf', 'application/zip', '.woff', '.woff2']
      },
      onBeforeUpload: (files) => {
        // We’ll be careful to return a new object, not mutating the original `files`
        const updatedFiles = {}
        Object.keys(files).forEach(fileID => {
          let newMeta = files[fileID].meta;
          newMeta.meta = JSON.stringify({
            name: newMeta.name,
            tags: newMeta.tags ? newMeta.tags.split(',') : []
          });
          updatedFiles[fileID] = {
            ...files[fileID],
            meta: newMeta
          }
        })
        return updatedFiles
      }
    })
    .use(Dashboard, {
      showProgressDetails: true,
      note: 'Images (jpg, png, pdf) and video (mp4) only, up to 35 MB',
      height: 470,
      metaFields: [
        { id: 'name', name: 'Name', placeholder: 'File name' },
        { id: 'tags', name: 'Tags', placeholder: 'File tags (comma separated)' }
      ],
      browserBackButtonClose: true,
      proudlyDisplayPoweredByUppy: false,
      trigger: '#upload-files'
    })
    .use(XHRUpload, { 
      endpoint: `${process.env.VUE_APP_PAIZA_ENDPOINT}/files`,
      fieldName: 'file',
      headers: {
        'authorization': `Bearer ${this.$auth.token()}`
      },
      metaFields: ['meta']
    });

    uppy.on('file-added', (file) => {
      uppy.setFileMeta(file.id, {
        meta: JSON.stringify({
          name: file.meta.name
        })
      })
    })

    uppy.on('complete', result => {
      this.loadFiles()
      console.log('successful files:', result.successful)
      console.log('failed files:', result.failed)
    });
  },
  data() {
    return {
      columns: [
        {
          label: 'Preview',
          field: 'url',
          sortable: false
        },
        {
          label: 'Name',
          field: 'meta.name',
          filterable: true,
          sortable: false
        },
        {
          label: 'Tags',
          field: 'meta.tags',
          filterable: true,
          formatFn: this.tagFormatter,
          sortable: false
        },
        {
          label: 'Type',
          field: 'fileType',
          filterable: true,
          formatFn: this.fileTypeFormatter
        },
        {
          label: 'Uploaded On',
          field: 'createdAt',
          type: 'date',
          formatFn: this.formatFn,
        },
        {
          label: 'Updated At',
          field: 'updatedAt',
          type: 'date',
          formatFn: this.formatFn,
        }
      ],
      loaded: false,
      isLoading: false,
      params: {
        page: 1,
        limit: 10,
        search: '',
        sort: {
          field: '', 
          type: '',
        },
      },
      files: [],
      count: 0
    };
  },
  methods: {
    formatFn: function(value) {
      var d = moment.tz(value,"UTC")
      return d.tz(moment.tz.guess()).format("MMM Do, YYYY HH:mm");
    },
    async loadFiles() {
      this.isLoading = true;
      let res = await Vue.axios.get('/files', {
        params: this.params
      });
      this.files = res.data.files;
      this.count = res.data.count;
      this.isLoading = false;
    },
    newFile() {
      this.$modal.show(FileModal, {
        finishedCallback: () => {
          this.loadFiles();
        }
      }, {
        adaptive: true,
        minHeight: 300,
        height: 'auto'
      })
    },
    editFile(params) {
      this.$modal.show(FileModal, {
        file: params.row,
        finishedCallback: () => {
          this.loadFiles();
        }
      }, {
        adaptive: true,
        minHeight: 300,
        scrollable: true,
        height: 'auto'
      })
    },
    fileTypeFormatter (fileType) {
      switch (fileType) {
        case 'image/jpeg':
          return 'Image (JPEG)';
        case 'application/pdf':
          return 'Document (PDF)';
        case 'image/png':
          return 'Image (PNG)';
        case 'video/mp4':
          return 'Video (MP4)';
        case 'model/usd':
          return 'Apple ARKit (USDZ)';
        case 'application/zip':
          return 'Archive (ZIP)';
        default:
          return 'Unknown';
      }
    },
    identityFormatter (identityId) {
      let identity = this.identities[identityId]
      return identity.username;
    },
    tagFormatter (tags) {
      return tags.join(', ');
    },
    updateParams(newProps) {
      this.params = Object.assign({}, this.params, newProps);
    },
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadFiles()
    },
    onPerPageChange(params) {
      this.updateParams({limit: params.currentPerPage});
      this.loadFiles()
    },
    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.loadFiles();
    },
    onSearch: _.debounce(function(params) {
      if (params.searchTerm.length == 0){
        this.updateParams({
          page: params.page = 1,
          search: params.searchTerm = ''
        })
        this.loadFiles()
      } else if (params.searchTerm.length < 3){
        return;
      }
      this.updateParams({search: params.searchTerm});
      this.loadFiles();
    }, 200),
  },
  created() {
    this.loadFiles().then(() => {
      this.loaded = true;
    }).catch((err) => {
      this.isLoading = false;
      bugsnagClient.notify(err);
      this.$notify({
        type: 'error',
        title: 'Failed to retrieve files.',
        text: 'Please try again later.'
      })
    })
  }
}
</script>
<style lang="scss" scoped>
  .previewImage {
    width: 80px;
    height: auto;
    border-radius: 8px;
  }
  .previewFile {
    text-align: center;
  }
</style>
