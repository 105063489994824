var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container grid-lg"},[_c('modals-container'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column col-12"},[_c('div',{staticClass:"card"},[(_vm.loaded)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"col-12 button-top"},[_c('button',{staticClass:"button-tool",on:{"click":_vm.newBatch}},[_c('plus-circle-icon',{staticClass:"icon"}),_vm._v(" New Batch")],1)])])]):_vm._e(),(!_vm.loaded)?_c('div',{staticClass:"loading loading-lg"}):_c('div',[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.columns,"totalRows":_vm.count,"rows":_vm.batches,"search-options":{
              enabled: true,
              placeholder: 'Search Batches'
            },"pagination-options":{
              enabled: true
            },"sort-options":{
              enabled: true,
              initialSortBy: {field: 'updatedAt', type: 'desc'}
            },"isLoading":_vm.isLoading},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-sort-change":_vm.onSortChange,"on-row-click":_vm.openBatch,"on-search":_vm.onSearch},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" No batches found. ")])])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }