<template>
    <div>
        <div class="container">
            <div id="reset-modal">
                <div class="column">
                    <div class="card">
                        <div class="content">
                            <h2>Reset Password</h2>
                            <form v-on:submit.prevent="reset()">
                                <div v-show="error" class="toast toast-error">
                                    Failed to reset password.
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="input-password">New Password</label>
                                    <input class="form-input" id="input-password" v-model="password" type="password">
                                </div>
                                <div class="form-group" v-bind:class="{ 'has-error': password != confirmPassword }">
                                    <label class="form-label" for="input-confirmpassword">Confirm New Password</label>
                                    <input class="form-input" id="input-confirmpassword"  v-model="confirmPassword" type="password">
                                    <p v-show="password != confirmPassword" class="form-input-hint">Passwords do not match.</p>
                                </div>
                                <div v-if="isLoading" class="loading loading-lg"></div>
                                <div v-else>
                                    <button type="submit" class="btn btn-primary" v-bind:disabled="password != confirmPassword">Reset</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import axios from 'axios';
    import bugsnagClient from '../utils/bugsnag';

    export default {
        name: 'PasswordReset',
        data() {
            return {
                token: '',
                password: '',
                confirmPassword: '',
                isLoading: false,
                error: null
            };
        },
        mounted() {
            this.token = this.$route.query.token
        },
        methods: {
            reset() {
                if (this.password != this.confirmPassword) {
                    return;
                }
                this.isLoading = true;
                axios.post(`${process.env.VUE_APP_PAIZA_ENDPOINT}/auth/reset`, {
                    newPassword: this.password
                }, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                })
                .then((res) => {
                    this.isLoading = false;
                    this.$auth.token(null, res.data.accessToken);
                    this.$auth.watch.loaded = true
                    this.$auth.watch.authenticated = true
                    this.$router.push({name: 'home'})
                    this.$notify({
                        type: 'success',
                        title: 'Password Reset Successfully!',
                        text: 'Successfully reset password for account.'
                    })
                }).catch((err) => {
                    this.isLoading = false;
                    this.error = err
                    bugsnagClient.notify(err);
                    this.$notify({
                        type: 'error',
                        title: 'Failed to reset password.',
                        text: 'Please try again later.'
                    })
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    #reset-modal {
        width: 360px;
        margin: 100px auto;
    }
    .btn {
        margin-top:1rem;
    }
</style>
