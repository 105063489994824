<template>
<div>
  <div class="operations-modal">
    <div class="header">
        <h3>Operations Log</h3>
    </div>
    <div v-if="!loaded" class="loading loading-lg"></div>
    <div v-else>
        <div class="info">
            <div v-if="!operations">
                <alert-octagon-icon class="empty-icon"></alert-octagon-icon>
                <p class="empty-title h5">Failed to load operations for asset.</p>
                <p>Please close and re-open to try again</p>
            </div>
            <div v-else>
                <p>This is a list of all operations that have occurred on this asset.</p>
                <p>"Verified" operations have a cryptographically verifiable audit trail.</p>
                <p>To enable CS Verified, please contact us.</p> 
            </div>
        </div>
        
        <vue-good-table
        v-if="operations"
        :columns="columns"
        :rows="operations"
        >
        <template slot="table-row" slot-scope="props">
            <div class="row-actions" v-if="props.column.field == 'verified'">
                {{props.formattedRow[props.column.field]}}
                <div v-show="props.formattedRow[props.column.field] == 'Verified'" class="tooltip tooltip-left" data-tooltip="Generate Proof">
                    <o-button buttonStyle="icon" @click.native="() => generateProof(props.row.id)">
                        <check-circle-icon></check-circle-icon>
                    </o-button>
                </div>
            </div>
            <div v-else>
            {{props.formattedRow[props.column.field]}}
            </div>
        </template>
        <div slot="emptystate">
            No operations found.
        </div>
        </vue-good-table>
    </div>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import { CheckCircleIcon } from 'vue-feather-icons'
import { AlertOctagonIcon } from 'vue-feather-icons';

import bugsnagClient from '../../utils/bugsnag';

import ProofDialog from '../../components/ProofDialog';

export default {
  name: 'AssetOperationsModal',
  components: { CheckCircleIcon, AlertOctagonIcon },
  props: ['id'],
  data() {
    return {
        columns: [
            {
                label: 'Type',
                field: 'type',
                formatFn: this.typeFormatter
            },
            {
                label: 'Timestamp',
                field: 'createdAt',
                type: 'date',
                dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS\'Z\'',
                dateOutputFormat: 'MMM dd, yyyy HH:mm'
            },
            {
                label: 'Identity',
                field: 'identityId',
                formatFn: this.identityFormatter
            },
            {
                label: 'Verified',
                field: 'verified',
                formatFn: this.verifiedFormatter
            }
        ],
        loaded: false,
        operations: null,
        identities: {}
    }
  },
  mounted() {
      this.loadOperations();
  },
  methods: {
    typeFormatter(type){
        switch (type) {
            case 0:
                return 'Created';
            case 1:
                return 'Updated';
            default:
                return 'Unknown';
        }
    },
    verifiedFormatter(verified){
        return verified ? 'Verified' : 'Unverified';
    },
    identityFormatter(identityId){
        var identity = this.identities[identityId];
        if (!identity) return 'Missing Identity';
        return identity.username;
    },
    async loadIdentity(identityId){
        if (this.identities[identityId]) return;
        var identityRes = await this.$http.get(`/identity/${identityId}`)
        var identity = identityRes.data;
        this.identities[identity.id] = identity;
    },
    async loadOperations() {
        this.loaded = false;
        try {
            var atomRes = await this.$http.get(`/atoms/${this.id}`)
            this.operations = atomRes.data.operations;
            for (let i = 0; i < this.operations.length; i++) {
                const op = this.operations[i];
                await this.loadIdentity(op.identityId)
            }
        } catch (err) {
            bugsnagClient.notify(err);
            this.$notify({
                type: 'error',
                title: 'Failed to load operations for asset.',
                text: 'Please try again later.'
            })
        }
        this.loaded = true;
    },
    generateProof(operationId) {
        this.$modal.show(ProofDialog, {
            operationId: operationId,
            finishedCallback: (completed) => {
            },
        }, {
            height: 300,
            width: 300,
            scrollable: false
        })
    },
  }
}
</script>
<style lang="scss" scoped>
  .operations-modal {
    .header {
        padding: 16px;
    }
    .info {
        padding-left: 16px;
        p {
            margin-bottom: 8px;
        }
        padding-bottom: 16px;
    }
  }
</style>
