import Vue from 'vue';
const _ = require('lodash');

const state = {
    lastMonth: [],
    currentQuery: []
};

const getters = {
    lastMonthSKUs: (state) => {
        let counts = _.countBy(state.lastMonth, 'sku');
        let mappedskus = _.map(counts, (count, sku) => {
            return {
                sku: sku,
                count: count
            }
        });
        return _.take(_.orderBy(mappedskus, 'count', 'desc'), 10);
    }
};

const mutations = {
    setLastMonth: (state, lastMonth) => {
        state.lastMonth = lastMonth;
    },
    setCurrentQuery: (state, currentQuery) => {
        state.currentQuery = currentQuery;
    }
};

const actions = {
    setLastMonth: async ({ commit, state }) => {
        let res = await Vue.axios.post('/scans', {
        });
        commit('setLastMonth', res.data.scans);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};