<template>
<div>
  <div v-if="uploading" class="loading-cover">
    <div>
      <div class="loading loading-lg"></div>
      <span>Uploading {{uploadedPercent}}% complete</span>
    </div>
  </div>
  <div class="file-modal">
      <div class="header">
        <div v-if="file">
          <div class="actions">
            <h3>Edit File</h3>
            <div class="buttons">
              <o-button style="padding-right: 8px" buttonStyle="alt" @click.native="downloadFile"><external-link-icon></external-link-icon> Download File</o-button>
              <o-button buttonStyle="alt" @click.native="deleteFile"><trash-2-icon></trash-2-icon> Delete File</o-button>
            </div>
          </div>
        </div>
        <div v-else>
          <h3>New File</h3>
        </div>
      </div>
      <div class="container">
        <div class="columns">
          <div class="col-6 col-md-12">
            <form>
              <div class="form-group">
                <label class="form-label" for="file-name">File Name</label>
                <input class="form-input" type="text" id="file-name" v-model="meta.name">
              </div>
              <div class="form-group">
                <label class="form-label" for="file-tags">File Tags <tip-modal title="File Tags" text="File Tags are internal identifiers intended to assist in searching. These tags should be seperated by commas"></tip-modal></label>
                <input-tag id="file-tags" v-model="meta.tags" :add-tag-on-blur="true"></input-tag>
              </div>
            </form>
          </div>
          <div class="column col-6 col-md-12">
            <div class="preview" v-if="file">
              <label class="form-label">File Preview</label>
              <div v-if="file.fileType == 'image/jpeg'">
                <figure class="figure">
                  <img class="img-responsive pic" v-bind:src="file.url">
                </figure>
              </div>
              <div v-if="file.fileType == 'image/png'">
                <figure class="figure">
                  <img class="img-responsive pic" v-bind:src="file.url">
                </figure>
              </div>
              <div v-if="file.fileType == 'video/mp4'">
                <video class="img-responsive" ref="media" controls>
                  <source v-bind:src="file.url">
                </video>
              </div>
              <div v-if="file.fileType == 'application/pdf'">
                <file-text-icon class="custom-class"></file-text-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="file-footer col-2">
        <o-button v-if="file" @click.native="saveFile">Save File</o-button>
      </div>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import { Trash2Icon, ExternalLinkIcon, SaveIcon, FileTextIcon, UploadCloudIcon } from 'vue-feather-icons'
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import bugsnagClient from '../../utils/bugsnag';

import ConfirmDialog from '../../components/ConfirmDialog';
import TipModal from '../../components/Tip';
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
  name: 'FileModal',
  props: ['file', 'finishedCallback'],
  data() {
    if (this.file){
      return {
        meta: Vue.util.extend({ name: '', tags: []}, this.file.meta),
        modified: false,
        uploading: false
      }
    } else {
      return {
        meta: { name: '', tags: []},
        modified: true,
        acceptedFiles: 'image/jpeg, application/pdf, video/mp4',
        files: [],
        uploadedFile: [],
        uploading: false,
        uploadedPercent: 0.0
      }
    }
  },
  watch: {
    meta: {
      handler: function (val, oldVal) {
        this.modified = true
      },
      deep: true
    }
  },
  components: {
    Trash2Icon,
    ExternalLinkIcon,
    SaveIcon,
    FileTextIcon,
    UploadCloudIcon,
    FilePond,
    TipModal
  },
  methods: {
    deleteFile() {
      this.$modal.show(ConfirmDialog, {
        finishedCallback: (confirmed) => {
          if (confirmed) {
            this.$http.delete(`/files/${this.file.id}`).then((res) => {
              if (this.finishedCallback) this.finishedCallback();
              this.$emit('close')
            }).catch((err) => {
              bugsnagClient.notify(err);
              this.$notify({
                type: 'error',
                title: 'Failed to delete file.',
                text: 'Please try again later.'
              });
            })
          }
        },
        message: 'Are you sure you want to delete this file?',
        primaryButtonTitle: 'Delete File'
      }, {
        height: 140,
        width: 300,
        scrollable: false
      })
    },
    downloadFile() {
      window.open(this.file.url);
    },
    saveFile() {
      this.$http.patch(`/files/${this.file.id}`, this.meta).then((res) => {
        if (this.finishedCallback) this.finishedCallback();
        this.$emit('close')
      }).catch((err) => {
        bugsnagClient.notify(err);
        this.$notify({
          type: 'error',
          title: 'Failed to save file.',
          text: 'Please try again later.'
        });
      })
    },
    newFile() {
      this.uploading = true;
      let formData = new FormData();
      formData.append('file', this.uploadedFile.file);
      formData.append('meta', JSON.stringify(this.meta));

      this.$http.post('/files',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            this.uploadedPercent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          }
        }).then((res) => {
          this.uploading = false;
          if (this.finishedCallback) this.finishedCallback();
          this.$emit('close')
        }).catch(function(err){
          this.uploading = false;
          bugsnagClient.notify(err);
          this.$notify({
            type: 'error',
            title: 'Failed to save file.',
            text: 'Please try again later.'
          });
        });
    },
    handleFilePondInit: function() {
      console.log('FilePond has initialized');
    },
    handleFilePondProcessfile: function (error, file) {
      console.log("FilePond succesfully processed file " + file.filename);
      this.uploadedFile = file;
      this.$nextTick();
    },
    handleFilePondRemovefile: function (file) {
      console.log("FilePond deleted file " + file.filename);
      var index = this.files.indexOf(file.filename);
      if (index > -1) {
        this.uploadedFile = null;
        this.$nextTick();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .preview {
    padding-left: 16px;
  }
  .btn-small {
    box-sizing: border-box;
    border: 0;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    background-color: floralwhite;
  }
  .file-modal {
    padding: 16px;
  }
  .file-footer {
    padding-top: 16px;
    bottom: 0;
    .save {
      float:right;
      margin: 16px;
    }
    .footer-text {
      display: inline-block;
      margin: 16px;
    }
    margin-bottom: 4px;
  }
  .header {
    padding-bottom: 16px;
  }
  .actions {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    .obutton {
      padding-right: 8px !important;
    }
    .buttons {
      display: inline-flex;
    }
  }
  .pond-holder {
    margin-top: 32px;
  }
  .pic {
    max-height: 200px;
  }

</style>
