<template>
<div class="selectorHolder">
    <draggable tag="div" :list="internalFiles" class="file-group" @change="orderChanged">
        <div v-for="(file) in internalFiles" :key="file" class="file-container">
            <div class="file-poster">
                <div v-if="!filesCache[file]" class="loading loading-lg"></div>
                <div class="file-internal" v-if="filesCache[file]" v-bind:style="generateFileStyle(filesCache[file])">
                    <div class="overlay-wrapper">
                        <button @click="removeFile(file)" class="filepond--file-action-button filepond--action-remove-item" type="button" title="Remove" data-align="left" style="transform:translate3d(0px, 0px, 0) scale3d(1, 1, 1) ;opacity:1;">
                            <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg"><path d="M11.586 13l-2.293 2.293a1 1 0 0 0 1.414 1.414L13 14.414l2.293 2.293a1 1 0 0 0 1.414-1.414L14.414 13l2.293-2.293a1 1 0 0 0-1.414-1.414L13 11.586l-2.293-2.293a1 1 0 0 0-1.414 1.414L11.586 13z" fill="currentColor" fill-rule="nonzero"></path></svg>
                        </button>
                        <div v-if="filesCache[file].fileType == 'video/mp4'">
                            <play-circle-icon class="icon"></play-circle-icon>
                        </div>
                        <div v-if="filesCache[file].fileType == 'application/pdf'">
                            <file-text-icon class="icon"></file-text-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </draggable>
    <div v-if="internalFiles.length < maxFiles" class="file-container" @click="addFile">
        <div class="file-add"><plus-circle-icon class="icon"></plus-circle-icon></div>
    </div>
</div>
</template>
<script>
const _ = require('lodash');
import { PlusCircleIcon, FileTextIcon, InfoIcon, PlayCircleIcon } from 'vue-feather-icons';
import draggable from 'vuedraggable';
import FileSearchModal from './FileSearchModal';

export default {
  name: 'FileSelector',
  props: {
    maxFiles: {
        default: 8,
        type: Number
    },
    files: {
        default: function() {
            if (this.maxFiles == 1){
                return null;
            } else {
                return [];
            }
        }
    },
    acceptedTypes: {
        default: function(){
            return [];
        },
        type: Array
    }
  },
  components: {
    PlusCircleIcon, FileTextIcon, InfoIcon, PlayCircleIcon, draggable
  },
  data() {
    return {
        filesCache: {},
        internalFiles: []
    }
  },
  mounted() {
    this.loadFiles();
  },
  methods: {
    loadFiles: async function() {
        if (this.maxFiles == 1 && this.files != null){
            this.getFile(this.files);
        } else if (this.maxFiles > 1 && this.files.length) {
            for (let i = 0; i < this.files.length; i++) {
                const file = this.files[i];
                try {
                    var fileRes = await this.$http.get(`/files/${file}`);
                    console.log(`retrieved file ${file}`)
                    this.internalFiles.push(file);
                    this.$set(this.filesCache, file, fileRes.data);
                } catch (err) {
                    console.log(err);
                }
            }
        }
    },
    getFile: function(id){
        this.$http.get(`/files/${id}`).then((res) => {
            console.log(`retrieved file ${id}`)
            this.internalFiles.push(id);
            this.$set(this.filesCache, id, res.data);
        }).catch(err => {
            console.log(err);
        })
    },
    generateFileStyle: function(file){
        switch (file.fileType) {
            case "image/jpeg":
                return {
                    "background": `url('${file.url}') no-repeat`,
                    "background-size": "cover",
                    "background-position-x": "50%",
                    "background-position-y": "50%"
                }
            case "image/png":
                return {
                    "background": `url('${file.url}') no-repeat`,
                    "background-size": "cover",
                    "background-position-x": "50%",
                    "background-position-y": "50%"
                }
            default:
                return {}
        }
    },
    addFile: function() {
        console.log('addfile pressed');
        this.$modal.show(FileSearchModal, {
            finishedCallback: (file) => {
                if (this.maxFiles == 1){
                    this.files = file;
                } else if (this.maxFiles > 1) {
                    this.files.push(file)
                }
                this.getFile(file);
                this.$emit('update', this.files);
            }
        }, {
            adaptive: true,
            minHeight: 400
        })
    },
    removeFile: function(file) {
        this.internalFiles = this.internalFiles.filter(function(internalFile) {
            return internalFile !== file
        })
        if (this.maxFiles == 1){
            this.files = null;
        } else if (this.maxFiles > 1) {
            this.files = this.files.filter(function(iFile) {
                return iFile !== file
            })
        }
        this.$emit('update', this.files);
    },
    orderChanged: function(event) {
        if (event.moved){
            var element = this.files[event.moved.oldIndex];
            this.files.splice(event.moved.oldIndex, 1)
            this.files.splice(event.moved.newIndex, 0, element);
            this.$emit('update', this.files);
        }
    }
  }
}
</script>
<style lang="scss" scoped>
    .selectorHolder {
        display: flex;
    }
    .file-group {
        display: flex;
        flex-wrap: wrap;
    }
    .file-container {
        display: flex;
        width: 88px;
        height: 88px;
        align-items: center;
        justify-content: center;     
        border-radius: 16px;
        background-color: #efefef;
        cursor: pointer;
    }
    .file-poster {
        height: 100%;
        width: 100%;
        color: #fff;
    }
    .overlay-wrapper {
        width: 100%;
        height: 100%;
        padding-top: 8px;
        padding-left: 8px;
        .icon {
            color: #000;
        }
    }
    .overlay {
        background-image: linear-gradient(to bottom, #000, rgba(0,0,0,0));
        opacity: 0.7;
        border-radius: 16px;
    }
    .file-internal {
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }
</style>
