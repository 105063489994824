import store from '../store';
import bugsnagClient from './bugsnag';

export const wrapRequest = fn => (...params) => 
  fn(...params).then(response => {
    return response;
  }).catch(error => handleError(error));

const prepareErrorMessage = error => {
    console.log(error);
    switch (error.response.status) {
      case 400:
        return error.response.data;
      case 401:
        return "Unauthorized action.";
      case 404:
        return "Not found.";
      case 500:
        return "An unknown error occurred."
      default:
        return "Error, please try again later";
    }
};
  
const handleError = error => {
    bugsnagClient.notify(error)
    const errorMessage = prepareErrorMessage(error);
    store.dispatch('notifications/populateNotifications', errorMessage);
};